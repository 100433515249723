import { atom } from 'recoil';

import { BasicModalProps, MODAL_TYPES } from 'components/modal';

export type BasicModalType = {
  modalType: typeof MODAL_TYPES.BasicModal;
  modalProps: BasicModalProps;
};

export type ModalType = BasicModalType;

export const modalState = atom<ModalType | null>({
  key: 'modalState',
  default: null,
});
