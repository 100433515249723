import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { Project } from 'models';
import { getAudiFuseApis } from 'proxy';
import { toProjectDetail } from 'services';

export const getProjectById = (projectId: Project['id']) =>
  getAudiFuseApis().projectsControllerGetProjectDetail(projectId);

export const useProject = (projectId: Project['id']) => {
  return useQuery(queryKeys.projects.detail(projectId), () => getProjectById(projectId), {
    select: (data) => toProjectDetail(data),
    enabled: !!projectId,
  });
};
