import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';

export const updateRole = (request: {
  id: string;
  title: string;
  fieldIds: string[];
  vehicleSystemId: string;
}) => getAudiFuseApis().adminControllerUpdateRole(request.id, request);

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.roles.all);
    },
  });
};
