import { atom } from 'recoil';

type ColumnVisibility = {
  columnVisibility: number;
};

export const columnVisibilityState = atom<ColumnVisibility>({
  key: 'columnVisibilityState',
  default: {
    columnVisibility: 0,
  },
});
