import { Comment, InputField, InputFieldHistory, Process, ProcessDetail } from 'models';
import {
  InputFieldHistoryDto,
  ProcessDetailDto,
  ProcessDto,
  InputFieldDto,
  CommentDto,
} from 'proxy';

export const toProcess = (data: ProcessDto): Process => ({
  id: data.id,
  columns: data?.columns && toColumns(data.id, data.columns),
});

export const toProcessDetail = (data: ProcessDetailDto): ProcessDetail => ({
  id: data.id,
  processArea: data.processArea,
  milestone: data.milestone,
  systemLevel: data.systemLevel,
  reifegradkriterium: data.reifegradkriterium,
  columns: data.columns.length > 0 ? toColumns(data.id, data.columns) : undefined,
  comments: data.comments ? data.comments.map(toComment) : [],
  links: data.links,
});

const toComment = (data: CommentDto): Comment => ({
  id: data.id,
  created: new Date(data.created),
  content: data.content,
  user: data.user,
});

export const toHistory = (data: InputFieldHistoryDto): InputFieldHistory => ({
  created: new Date(data.created),
  title: data.title,
  value: data.value,
  user: data.user,
});

const toColumns = (processId: string, data: InputFieldDto[]): { [key: string]: InputField } =>
  Object.assign(
    {},
    ...data.map((d) => ({
      [d.title]: {
        id: d.id,
        processId,
        value: d.value,
        isInputField: d.isInputField,
        previousValue: d.previousValue && toHistory(d.previousValue),
        type: d.type ?? 'string',
        isEditable: d.id ? true : false,
      },
    }))
  );
