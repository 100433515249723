import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridRowModel, GridToolbarContainer } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';

import { useCreateRole, useFields, useRoles, useUpdateRole } from 'api/admin';
import { KBD } from 'components';
import { useModal } from 'hooks/useModal';

import { CreateProjectModal } from './CreateProjectModal';
import { VehicleSystemTree } from './VehicleSystemTree';

export const RolePermissionDashboard = () => {
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);

  const { data: fieldsData } = useFields(selectedVehicleId ?? '');

  const { data: rolesData } = useRoles(selectedVehicleId ?? '');
  const { showModal, hideModal } = useModal();

  const createRoleMutation = useCreateRole();
  const updateRoleMutation = useUpdateRole();

  const columns: GridColumns = [
    { field: 'title', headerName: 'Titel', width: 100, editable: true },
    ...(fieldsData
      ? fieldsData.map((field) => ({
          field: field.title,
          headerName: field.title,
          type: 'boolean',
          editable: true,
        }))
      : []),
  ];

  const { enqueueSnackbar } = useSnackbar();

  const processRowUpdate = async (newRole: GridRowModel) => {
    const fieldIds: string[] = Object.keys(newRole)
      .filter((key) => newRole?.[key] === true)
      .map((key) => fieldsData?.find((fd) => fd.title === key)?.id ?? '')
      .filter((v) => v !== '');

    updateRoleMutation.mutate({
      id: newRole.id,
      title: newRole.title,
      fieldIds,
      vehicleSystemId: selectedVehicleId ?? '',
    });

    return {
      id: newRole.id,
      title: newRole.title,
      fieldIds,
    };
  };

  const handleProcessRowUpdateError = (error: Error) => {
    enqueueSnackbar(`${error.message}`, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const onAddRole = () => {
    showModal({
      modalType: 'BasicModal',
      modalProps: {
        title: `Rolle anlegen`,
        content: (
          <CreateProjectModal
            onCancel={hideModal}
            onSave={(title) => {
              createRoleMutation.mutate({ title });
              hideModal();
            }}
          />
        ),
      },
    });
  };

  return (
    <Grid container spacing={2} flex={1} maxHeight='80vh'>
      <Grid item xs={12} sm={6} md={4} lg={3} maxHeight='100%'>
        <VehicleSystemTree onSelect={(id) => setSelectedVehicleId(id)} />
      </Grid>
      <Grid item xs={12} sm={6} md={8} lg={9}>
        {selectedVehicleId ? (
          <Fragment>
            <Typography sx={{ paddingY: 2 }} variant='body1'>
              Um Werte in der Tabelle zu editieren: Doppelklick und Eingabe mit <KBD>Enter</KBD>{' '}
              bestätigen und &nbsp;<KBD>ESC</KBD> zum abbrechen
            </Typography>
            <DataGrid
              sx={{ overflow: 'scroll' }}
              autoHeight
              hideFooter
              disableColumnFilter
              disableDensitySelector
              disableColumnMenu
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button color='primary' startIcon={<AddIcon />} onClick={onAddRole}>
                      Rolle hinzufügen
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
              rows={rolesData ?? []}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              columns={columns}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Fragment>
        ) : (
          <Typography>Bitte eine Auswahl treffen</Typography>
        )}
      </Grid>
    </Grid>
  );
};
