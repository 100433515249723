/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Audi Fuse Apis
 * The Audi Fuse API descriptions
 * OpenAPI spec version: 0.1
 */
import { customAxios } from '../lib/axios';

import type {
  AdminControllerGetFieldsParams,
  AdminControllerGetRolesParams,
  CommentRequest,
  CreateProjectRequest,
  CreateRoleRequest,
  FieldDto,
  FilterDto,
  InputFieldHistoryDto,
  ProcessControllerGetProcessDetailParams,
  ProcessDetailDto,
  ProjectDetailDto,
  ProjectDto,
  ProjectFilterDto,
  ProjectsControllerGetProjectProcessParams,
  RoleDto,
  UpdateCellRequest,
  UpdateProjectRequest,
  UpdateRoleRequest,
} from './dtos';

export const getAudiFuseApis = () => {
  const projectsControllerGetProjects = () => {
    return customAxios<ProjectDto[]>({ url: `/api/projects`, method: 'get' });
  };

  const projectsControllerGetProjectFilters = () => {
    return customAxios<ProjectFilterDto>({ url: `/api/projects/filters`, method: 'get' });
  };

  const projectsControllerGetBusinessObjects = () => {
    return customAxios<FilterDto[]>({ url: `/api/projects/businessObjects`, method: 'get' });
  };

  const projectsControllerGetProjectDetail = (id: string) => {
    return customAxios<ProjectDetailDto>({ url: `/api/projects/${id}`, method: 'get' });
  };

  const projectsControllerGetProjectProcess = (
    id: string,
    params: ProjectsControllerGetProjectProcessParams
  ) => {
    if (params?.systemLevelId === '') {
      return {};
    } else {
      return customAxios<ProjectDetailDto>({
        url: `/api/projects/${id}/process`,
        method: 'get',
        params,
      });
    }
  };

  const processControllerGetProcessDetail = (
    id: string,
    params: ProcessControllerGetProcessDetailParams
  ) => {
    return customAxios<ProcessDetailDto>({
      url: `/api/process/${id}`,
      method: 'get',
      params,
    });
  };

  const processControllerGetProcessHistory = (id: string) => {
    return customAxios<InputFieldHistoryDto[]>({
      url: `/api/process/${id}/history`,
      method: 'get',
    });
  };

  const processControllerUpdateCells = (updateCellRequest: UpdateCellRequest) => {
    return customAxios<void>({
      url: `/api/process`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateCellRequest,
    });
  };

  const processControllerAddComment = (id: string, commentRequest: CommentRequest) => {
    return customAxios<void>({
      url: `/api/process/${id}/comments`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: commentRequest,
    });
  };

  const adminControllerUpdateProject = (updateProjectRequest: UpdateProjectRequest) => {
    return customAxios<void>({
      url: `/api/admin/project`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateProjectRequest,
    });
  };

  const adminControllerCreateProject = (createProjectRequest: CreateProjectRequest) => {
    return customAxios<void>({
      url: `/api/admin/project`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createProjectRequest,
    });
  };

  const adminControllerGetProjects = () => {
    return customAxios<ProjectDto[]>({ url: `/api/admin/projects`, method: 'get' });
  };

  const adminControllerGetRoles = (params: AdminControllerGetRolesParams) => {
    return customAxios<RoleDto[]>({ url: `/api/admin/roles`, method: 'get', params });
  };

  const adminControllerCreateRole = (createRoleRequest: CreateRoleRequest) => {
    return customAxios<void>({
      url: `/api/admin/roles`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createRoleRequest,
    });
  };

  const adminControllerUpdateRole = (roleId: string, updateRoleRequest: UpdateRoleRequest) => {
    return customAxios<void>({
      url: `/api/admin/roles/${roleId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateRoleRequest,
    });
  };

  const adminControllerGetFields = (params: AdminControllerGetFieldsParams) => {
    return customAxios<FieldDto[]>({ url: `/api/admin/fields`, method: 'get', params });
  };

  const adminControllerGetVehicleSystems = () => {
    return customAxios<FilterDto[]>({ url: `/api/admin/vehicle-systems`, method: 'get' });
  };

  return {
    projectsControllerGetProjects,
    projectsControllerGetProjectFilters,
    projectsControllerGetBusinessObjects,
    projectsControllerGetProjectDetail,
    projectsControllerGetProjectProcess,
    processControllerGetProcessDetail,
    processControllerGetProcessHistory,
    processControllerUpdateCells,
    processControllerAddComment,
    adminControllerUpdateProject,
    adminControllerCreateProject,
    adminControllerGetProjects,
    adminControllerGetRoles,
    adminControllerCreateRole,
    adminControllerUpdateRole,
    adminControllerGetFields,
    adminControllerGetVehicleSystems,
  };
};
export type ProjectsControllerGetProjectsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['projectsControllerGetProjects']>>
>;
export type ProjectsControllerGetProjectFiltersResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['projectsControllerGetProjectFilters']>>
>;
export type ProjectsControllerGetBusinessObjectsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['projectsControllerGetBusinessObjects']>>
>;
export type ProjectsControllerGetProjectDetailResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['projectsControllerGetProjectDetail']>>
>;
export type ProjectsControllerGetProjectProcessResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['projectsControllerGetProjectProcess']>>
>;
export type ProcessControllerGetProcessDetailResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['processControllerGetProcessDetail']>>
>;
export type ProcessControllerGetProcessHistoryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['processControllerGetProcessHistory']>>
>;
export type ProcessControllerUpdateCellsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['processControllerUpdateCells']>>
>;
export type ProcessControllerAddCommentResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['processControllerAddComment']>>
>;
export type AdminControllerUpdateProjectResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerUpdateProject']>>
>;
export type AdminControllerCreateProjectResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerCreateProject']>>
>;
export type AdminControllerGetProjectsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerGetProjects']>>
>;
export type AdminControllerGetRolesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerGetRoles']>>
>;
export type AdminControllerCreateRoleResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerCreateRole']>>
>;
export type AdminControllerUpdateRoleResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerUpdateRole']>>
>;
export type AdminControllerGetFieldsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerGetFields']>>
>;
export type AdminControllerGetVehicleSystemsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAudiFuseApis>['adminControllerGetVehicleSystems']>>
>;
