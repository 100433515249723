import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { Box } from '@mui/material';

import { useVehicleSystems } from 'api/admin';
import { Filter } from 'models';

type VehicleSystemTreeProps = {
  onSelect?: (id: string) => void;
};

export const VehicleSystemTree = ({ onSelect }: VehicleSystemTreeProps) => {
  const { data: vehicleSystemsData } = useVehicleSystems();

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    onSelect && onSelect(nodeIds[0].split('#')[0]);
  };

  const renderItem = (filter: Filter, parentId: string | null) => (
    <TreeItem
      key={`${filter.id}#${parentId}`}
      nodeId={`${filter.id}#${parentId}`}
      label={filter.title}
    >
      {filter.children && filter.children.map((value) => renderItem(value, filter.id))}
    </TreeItem>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'scroll',
        maxHeight: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: 1,
      }}
    >
      <TreeView
        aria-label='controlled'
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {vehicleSystemsData?.map((value) => renderItem(value, null))}
      </TreeView>
    </Box>
  );
};
