import { useRecoilValue } from 'recoil';

import { modalState } from 'store/modal';

import { BasicModal } from './BasicModal';

export const MODAL_TYPES = {
  BasicModal: 'BasicModal',
  ConfirmDeleteModal: 'ConfirmDeleteModal',
} as const;

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.BasicModal]: BasicModal,
};

export const GlobalModal = () => {
  const { modalType, modalProps } = useRecoilValue(modalState) ?? {};

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    const ModalComponent = MODAL_COMPONENTS[modalType];

    return <ModalComponent {...modalProps} />;
  };

  return <>{renderComponent()}</>;
};
