import { Settings, Logout, AccountCircle } from '@mui/icons-material';
import { Tooltip, IconButton, Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { Auth } from 'aws-amplify';
import { Fragment, useState, MouseEvent } from 'react';
import { useRecoilState } from 'recoil';

import { ROUTE_CONSTANTS } from 'routes';
import { authState } from 'store';
import { stringAvatar } from 'utils';

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [auth, setAuth] = useRecoilState(authState);
  const isAdmin = auth && (auth['cognito:groups'] as string[])?.includes('admin');

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setAuth({});
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const handleAdminLink = () => {
    navigate({ to: `/${ROUTE_CONSTANTS.ADMIN_PATH}` });
  };

  return (
    <Fragment>
      <Tooltip title='Nutzereinstellungen'>
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            style={{ cursor: 'pointer' }}
            {...stringAvatar(`${auth['given_name']} ${auth['family_name']}`)}
            alt={`${auth['given_name']} ${auth['family_name']}`}
            src='/broken-image.jpg'
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>
          <ListItemIcon>
            <AccountCircle fontSize='small' />
          </ListItemIcon>
          {auth['given_name']} {auth['family_name']}
        </MenuItem>
        {isAdmin ? (
          <MenuItem onClick={handleAdminLink}>
            <ListItemIcon>
              <Settings fontSize='small' />
            </ListItemIcon>
            Verwaltung (Admin)
          </MenuItem>
        ) : null}
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Abmelden
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
