import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis, UpdateCellRequest } from 'proxy';

export const updateProcess = (data: UpdateCellRequest) =>
  getAudiFuseApis().processControllerUpdateCells(data);

export const useUpdateProcess = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation(updateProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.projects.details());
      queryClient.invalidateQueries(queryKeys.process.details());
      enqueueSnackbar(`Änderung gespeichert.`, {
        variant: 'success',
        preventDuplicate: true,
      });
    },
  });
};
