export type Process = {
  id: string;
  columns: { [key: string]: InputField };
};

export type ProcessDetail = {
  id: string;
  processArea: string;
  milestone: string;
  systemLevel: string;
  reifegradkriterium: string;
  columns?: { [key: string]: InputField };
  comments: Comment[];
  links: Link[];
};

export type Link = {
  uri: string;
  text: string;
};

export type InputField = {
  id?: string;
  processId?: string;
  value: string | null;
  previousValue?: InputFieldHistory;
  type: InputFieldType;
  isEditable: boolean;
  isInputField: boolean;
};

export type Comment = {
  id: string;
  created: Date;
  content: string;
  user: string;
};

export type InputFieldHistory = {
  created: Date;
  title?: string;
  value: string;
  user: string;
};

export type InputFieldType = typeof InputFieldType[keyof typeof InputFieldType];

export const InputFieldType = {
  date: 'date',
  dateplus: 'dateplus',
  boolean: 'boolean',
  string: 'string',
} as const;
