import { TableCell } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import { useMemo } from 'react';

type MemoTableCellProps = {
  cell: Cell<any, any>;
  userColumns: string[];
};

export const MemoTableCell = ({ cell, userColumns }: MemoTableCellProps) => {
  return useMemo(
    () => (
      <TableCell
        align='left'
        key={cell.id}
        style={{
          borderRight: userColumns.includes(cell.column.id) ? 'solid rgba(255, 255, 255, .6)' : '',
          backgroundColor: userColumns.includes(cell.column.id)
            ? 'rgba(13, 153, 255, .1)'
            : 'rgba(255, 255, 255, .6)',
        }}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    ),
    [userColumns, cell]
  );
};
