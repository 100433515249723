import { AppBar, Stack, styled, Toolbar, Typography } from '@mui/material';
import { Link } from '@tanstack/react-location';

import { ReactComponent as Logo } from 'assets/audi-logo.svg';
import { ROUTE_CONSTANTS } from 'routes';

import { AccountMenu } from './AccountMenu';

export const StyledAppBar = styled(AppBar)(
  ({ theme }) => `
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.text.primary};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
`
);

export const Header = () => {
  return (
    <StyledAppBar>
      <Toolbar
        component='nav'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction='row' alignItems={'center'}>
          <Logo style={{ paddingRight: '1em' }} />
          <Link
            to={ROUTE_CONSTANTS.HOME_PATH}
            style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                flexGrow: 1,
                fontWeight: 700,
                borderLeft: '1px solid #DEE2E6',
                paddingLeft: '1em',
              }}
            >
              SysE - Tool
            </Typography>
          </Link>
        </Stack>
        <Stack direction='row' spacing={4}>
          <AccountMenu />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
};
