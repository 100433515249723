import { debounce } from '@mui/material';
import { useCallback, useRef } from 'react';

export const isBoolean = (value: string) => (value === 'true' ? true : false);

export const useSingleAndDoubleClick = (onClick, onDoubleClick) => {
  const DELAY = 250;
  const clicks = useRef(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callFunction = useCallback(
    debounce(() => {
      clicks.current === 3 ? onDoubleClick() : onClick();
      clicks.current = 0;
    }, DELAY),
    []
  );

  const handleClick = () => {
    clicks.current++;
    callFunction();
  };

  const handleDoubleClick = () => {
    clicks.current++;
  };

  return { handleClick, handleDoubleClick };
};

export const parseValue = (value: string | null) => {
  switch (value) {
    case 'true':
      return 'Ja';
    case 'false':
      return 'Nein';
    case null:
      return 'Kein Eintrag';
    default:
      return value;
  }
};
