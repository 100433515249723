import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';
import { toRole } from 'services/role.service';

export const getRoles = (systemLevelId: string) =>
  getAudiFuseApis().adminControllerGetRoles({ systemLevelId });

export const useRoles = (systemLevelId: string) => {
  return useQuery(queryKeys.roles.byVehicleSystemId(systemLevelId), () => getRoles(systemLevelId), {
    select: (data) => data.map(toRole),
  });
};
