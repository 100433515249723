import { zodResolver } from '@hookform/resolvers/zod';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Stack,
  Divider,
} from '@mui/material';
import { useSearch, useMatch } from '@tanstack/react-location';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useProject } from 'api';
import { Filter } from 'models';
import { LocationGenerics } from 'routes';
import { storage } from 'utils/storage';

import { SavedFilters } from './filters/Filters';

type SaveFilterModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

type FormInput = {
  filterName: string;
};

const schema = z.object({
  filterName: z
    .string()
    .min(1, { message: 'Bitte Namen eingeben' })
    .max(100, { message: 'Name begrenzt auf 100 Zeichen' }),
});

export const SaveFilterModal = ({ isOpen, handleClose }: SaveFilterModalProps) => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: { filterName: '' },
  });
  const search = useSearch<LocationGenerics>();
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();

  const projectQuery = useProject(projectId);

  const { t } = useTranslation();

  const saveFilters = (formData: FormInput) => {
    if (search.filters) {
      const buildSavedFitlers: SavedFilters = {
        vehicleProjectId: projectId,
        filterName: `${formData.filterName} | ${projectQuery?.data?.title}`,
        ...search.filters,
      };
      storage.addNewFilter(buildSavedFitlers);
      handleClose();
    }
  };

  const watchFilterName: string = watch('filterName');

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle fontWeight={700}>
        Neuen Favoriten hinzufügen
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider variant='middle' />
      <form onSubmit={handleSubmit(saveFilters)}>
        <DialogContent>
          <DialogContentText mb={2}>
            Bitte geben Sie den Namen für Ihren Favoriten ein.
          </DialogContentText>
          <Controller
            name='filterName'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant='outlined'
                label='Name Favorit'
                required
                helperText={
                  <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='caption'>
                      {errors.filterName && `${errors.filterName?.message}: `}bspw. Fahrzeugprojekt
                      eQ8
                    </Typography>
                    {watchFilterName && (
                      <Typography variant='caption'>{watchFilterName.length}/100</Typography>
                    )}
                  </Stack>
                }
                error={!!errors.filterName}
              />
            )}
          />
          <Typography paragraph={false} mt={2} pb={1} fontWeight={700}>
            Ausgewählte Filter
          </Typography>
          <Box bgcolor='#EDF0F2' borderRadius='4px' p={2}>
            {search.filters &&
              Object.entries(search.filters).map(([key, values], index) => (
                <Typography key={key + index} mb={2}>
                  {t(key)}:{' '}
                  <span style={{ color: '#008DDD' }}>
                    {(values as Filter[]).map((filter) => filter.title)?.join(' | ')}
                  </span>
                </Typography>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type='submit' variant='contained' disabled={!isValid}>
            Favorit hinzufügen
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
