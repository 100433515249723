import { Button, Grid, Stack, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';

import { ErrorMessage } from 'models';
import { ROUTE_CONSTANTS } from 'routes';
import { storage } from 'utils/storage';

export const resetErrorBoundary = () => {
  storage.removeAllTokens();
  window.location.href = '/';
};

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const redirectHome = () => {
    window.location.replace(ROUTE_CONSTANTS.HOME_PATH);
  };
  const renderFallback = () => {
    if (axios.isAxiosError(error)) {
      switch ((error as AxiosError<any>)?.response?.data?.message) {
        case ErrorMessage.E403P:
          return (
            <Stack direction='column'>
              <Typography>You are not authorized to access this page</Typography>
              <Button onClick={redirectHome}>Back home</Button>
            </Stack>
          );
        case ErrorMessage.E401ATE:
        case ErrorMessage.E401BC:
        case ErrorMessage.E401RTE:
          return (
            <Stack direction='column'>
              <Typography>Your session has expired, please login again to continue</Typography>
              <Button onClick={resetErrorBoundary}>Login</Button>
            </Stack>
          );
        default:
          return (
            <Stack direction='column'>
              <Typography>Something unexpected happened</Typography>
              <Button onClick={redirectHome}>Back home</Button>
            </Stack>
          );
      }
    } else {
      return (
        <Stack direction='column'>
          <Typography>Something unexpected happened</Typography>
          <Button onClick={resetErrorBoundary}>Restart app</Button>
        </Stack>
      );
    }
  };
  return (
    <Grid container justifyContent='center' alignItems='center' height='100vh'>
      {renderFallback()}
    </Grid>
  );
};
