import TableViewIcon from '@mui/icons-material/TableView';
import { Box, Button } from '@mui/material';
import { Table as ReactTableType } from '@tanstack/react-table';
import { Fragment, useState } from 'react';

import { Process } from 'models';

import { EditTableColumnsModal } from './EditTableColumnsModal';

export type TableProps = {
  table: ReactTableType<Process['columns']>;
  editableColumns: string[];
};

export const EditTableColumns = ({ table, editableColumns }: TableProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      <Box display='flex' justifyContent='flex-end' m={2}>
        <Button
          variant='outlined'
          startIcon={<TableViewIcon />}
          onClick={openModal}
          sx={{ textTransform: 'none' }}
        >
          Tabellenansicht bearbeiten
        </Button>
      </Box>
      {isModalOpen && (
        <EditTableColumnsModal
          table={table}
          isOpen={isModalOpen}
          handleClose={closeModal}
          editableColumns={editableColumns}
        />
      )}
    </Fragment>
  );
};
