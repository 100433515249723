import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { Process } from 'models';
import { getAudiFuseApis } from 'proxy';
import { toHistory } from 'services';

export const getProcessHistoryById = (processId: Process['id']) =>
  getAudiFuseApis().processControllerGetProcessHistory(processId);

export const useProcessHistory = (processId: Process['id']) => {
  return useQuery(
    queryKeys.process.detailWithHistory(processId),
    () => getProcessHistoryById(processId),
    {
      select: (data) => data.map(toHistory),
      enabled: !!processId,
    }
  );
};
