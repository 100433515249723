import { Alert, Grid, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { Fragment } from 'react';

import { Project } from 'models';

import { ProjectCard } from './ProjectCard';

type VehicleProjectsProps = {
  projects: UseQueryResult<Project[], unknown>;
};

export const VehicleProjects = ({ projects }: VehicleProjectsProps) => {
  if (projects.isError) {
    return <Alert severity='error'>Fehler bei der Projektabfrage</Alert>;
  }
  if (projects.isLoading) {
    return (
      <Typography variant='h6' component='h4' fontWeight={700} marginBottom={4}>
        Laden
      </Typography>
    );
  }

  return (
    <Fragment>
      <Typography variant='h6' component='h4' fontWeight={700} marginBottom={4}>
        Fahrzeugprojekte
      </Typography>
      <Grid container spacing={{ xs: 1 }} columns={{ xs: 1 }}>
        {projects?.data?.map((project) => (
          <Grid item xs={2} sm={4} md={4} key={project.id}>
            <ProjectCard {...project} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
