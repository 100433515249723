import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PromiseValue } from 'type-fest';

import {
  ProcessControllerGetProcessDetailParams,
  ProjectsControllerGetProjectProcessParams,
} from 'proxy';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: process.env.NODE_ENV === 'development' ? false : true,
    staleTime: 0,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>[0]
>;

// Query Key factories => https://tkdodo.eu/blog/effective-react-query-keys
export const queryKeys = {
  projects: {
    all: ['projects'] as const,
    lists: () => [...queryKeys.projects.all, 'list'] as const,
    list: () => [...queryKeys.projects.lists()] as const,
    details: () => [...queryKeys.projects.all, 'detail'] as const,
    detail: (id: string) => [...queryKeys.projects.details(), id] as const,
    detailWithParams: (id: string, filters: ProjectsControllerGetProjectProcessParams) =>
      [...queryKeys.projects.detail(id), { filters }] as const,
  },
  process: {
    all: ['process'] as const,
    lists: () => [...queryKeys.process.all, 'list'] as const,
    list: () => [...queryKeys.process.lists()] as const,
    details: () => [...queryKeys.process.all, 'detail'] as const,
    detail: (id: string) => [...queryKeys.process.details(), id] as const,
    detailWithHistory: (id: string) => [...queryKeys.process.details(), id] as const,
    detailWithParams: (id: string, filters: ProcessControllerGetProcessDetailParams) =>
      [...queryKeys.process.detail(id), { filters }] as const,
  },
  filters: {
    all: ['filters'] as const,
    lists: () => [...queryKeys.filters.all, 'list'] as const,
    list: () => [...queryKeys.filters.lists()] as const,
    details: () => [...queryKeys.filters.all, 'detail'] as const,
    detail: (id: string) => [...queryKeys.filters.details(), id] as const,
  },
  businessObjects: {
    all: ['businessObjects'] as const,
  },
  fields: {
    all: ['fields'] as const,
    byVehicleSystemId: (vehicleSystemId: string) =>
      [...queryKeys.fields.all, vehicleSystemId] as const,
  },
  vehicleSystems: {
    all: ['vehicleSystems'] as const,
  },
  roles: {
    all: ['roles'] as const,
    byVehicleSystemId: (vehicleSystemId: string) =>
      [...queryKeys.roles.all, vehicleSystemId] as const,
  },
  adminProjects: {
    all: ['projects'] as const,
  },
};
