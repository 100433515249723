import { FilterAltOff } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import React, { useState } from 'react';

interface TreeNode {
  id: string;
  title: string;
  children?: TreeNode[];
}

interface ControlledTreeViewProps {
  filterData: any;
  onSystemLevelSearch: any;
  selectedCheckboxes: any;
  resetHeadFilters: any;
}

const StyledTreeItem = styled(TreeItem)`
  max-width: 390px;
`;

function ControlledTreeView({
  filterData,
  onSystemLevelSearch,
  selectedCheckboxes,
  resetHeadFilters,
}: ControlledTreeViewProps) {
  const data = filterData;
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>(selectedCheckboxes?.systemLevels || []);

  const handleToggle = (event: React.ChangeEvent<any>, nodeIds: string[]) => {
    if (event.target && event.target.nodeName !== 'svg') {
      return;
    }
    setExpanded(nodeIds);
  };

  const getAllCheckboxHierarchy = (checkbox: TreeNode, addItemIdToList: boolean): string[] => {
    const subFolderCheckboxes: string[] = [];
    checkbox.children?.forEach((child) => {
      if (child.children) {
        subFolderCheckboxes.push(...child.children.map((child) => child.id));
      }
      subFolderCheckboxes.push(child.id);
    });

    if (addItemIdToList) {
      subFolderCheckboxes.push(checkbox.id);
    }

    return subFolderCheckboxes;
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, item: TreeNode) => {
    if (event.target && event.target.nodeName === 'svg') {
      return;
    }

    const isChecked = selected.includes(item.id);
    let newSelected: string[];

    if (item.children) {
      const checkboxHierarchy = getAllCheckboxHierarchy(item, true);
      if (isChecked) {
        newSelected = selected.filter((id) => !checkboxHierarchy.find((child) => child === id));
      } else {
        newSelected = [...selected, ...checkboxHierarchy];
        setExpanded([...expanded, item.id]);
      }
    } else {
      newSelected = isChecked ? selected.filter((id) => id !== item.id) : [...selected, item.id];
    }

    setSelected(newSelected);
  };

  const getStatusSelectedChildren = (item: TreeNode): 'nothing' | 'all' | 'indeterminate' => {
    const allChildrenIds = getAllChildrenIds(item);

    const count = selected.filter((id) => allChildrenIds.find((child) => child === id));
    if (count.length === 0) return 'nothing';
    if (count.length >= allChildrenIds.length) return 'all';

    return 'indeterminate';
  };

  const handleSearch = () => {
    onSystemLevelSearch(selected);
  };

  const handleReset = () => {
    setExpanded([]);
    setSelected([]);
    resetHeadFilters();
  };

  const getAllChildrenIds = (item: TreeNode): string[] => {
    let childrenIds: string[] = [];
    if (item.children) {
      for (const child of item.children) {
        childrenIds.push(child.id);
        childrenIds = childrenIds.concat(getAllChildrenIds(child));
      }
    }
    return childrenIds;
  };

  const renderTreeItems = (items: TreeNode[]) => {
    return items.map((item) => (
      <StyledTreeItem
        key={item.id}
        nodeId={item.id}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  item.children
                    ? getStatusSelectedChildren(item) === 'all'
                    : selected.includes(item.id)
                }
                onChange={(e) => handleCheckboxChange(e, item)}
                indeterminate={item.children && getStatusSelectedChildren(item) === 'indeterminate'}
              />
            }
            label={item.title}
          />
        }
      >
        {item.children && (
          <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
            {renderTreeItems(item.children)}
          </div>
        )}
      </StyledTreeItem>
    ));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', margin: '0 -65px' }}>
      <TreeView
        defaultCollapseIcon={<ExpandLessIcon />}
        defaultExpandIcon={<ExpandMoreIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        multiSelect
      >
        <FormGroup row>{renderTreeItems(data)}</FormGroup>
      </TreeView>
      <div style={{ paddingTop: '5px' }}>
        <Button
          startIcon={<FilterAltOff fontSize='small' />}
          onClick={handleReset}
          style={{ maxHeight: '40px' }}
        >
          <Typography sx={{ fontSize: 10 }}>Filter zurücksetzen</Typography>
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={handleSearch}
          disabled={selected.length === 0}
          style={{ maxHeight: '40px' }}
        >
          <Typography sx={{ fontSize: 10 }}>Aktualisieren</Typography>
        </Button>
      </div>
    </div>
  );
}

export default ControlledTreeView;
