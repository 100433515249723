import { Visibility } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Typography,
  Box,
  Grid,
  Divider,
  Autocomplete,
  Checkbox,
  TextField,
  Stack,
  Button,
} from '@mui/material';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { Table as ReactTableType } from '@tanstack/react-table';
import { SyntheticEvent } from 'react';

import { useBusinessObjects } from 'api';
import { Filter, Process } from 'models';
import { LocationGenerics } from 'routes';

import { EditTableColumns } from '../EditTableColumns';

export type TableProps = {
  table: ReactTableType<Process['columns']>;
  editableColumns: string[];
  totalItems: number;
  onShowOnlyOwnedColumnClick: () => void;
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const TableHeader = ({
  table,
  editableColumns,
  totalItems,
  onShowOnlyOwnedColumnClick,
}: TableProps) => {
  const navigate = useNavigate<LocationGenerics>();
  const businessObjectsQuery = useBusinessObjects();
  const { filters } = useSearch<LocationGenerics>();

  const defaultValue = filters?.businessObjects ?? [];

  const handleBusinessObjectFilter = (_event: SyntheticEvent<Element, Event>, values: Filter[]) => {
    navigate({
      search: (prev?) => ({
        ...prev,
        filters: { ...prev?.filters, businessObjects: values.length ? values : undefined },
      }),
    });
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography component={'h1'} sx={{ fontSize: 22 }} fontWeight={700} marginBottom={2}>
            Vorgänge ({totalItems})
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container alignContent='center' justifyContent='space-between'>
        <Grid item alignSelf='center'>
          <Autocomplete
            fullWidth
            size='small'
            multiple
            id='business-areas'
            options={businessObjectsQuery.data ?? []}
            onChange={handleBusinessObjectFilter}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            defaultValue={[...defaultValue]}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  value={option.id}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label='Geschäftsobjekte filtern' />}
          />
        </Grid>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='outlined'
              startIcon={<Visibility />}
              onClick={onShowOnlyOwnedColumnClick}
              sx={{ textTransform: 'none' }}
            >
              Nur eigene Felder anzeigen
            </Button>
          </Box>
          <EditTableColumns table={table} editableColumns={editableColumns} />
        </Stack>
      </Grid>
    </Box>
  );
};
