import { Box, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import deLocale from 'date-fns/locale/de';

import { ProcessDetail } from 'models';

type GeneralProps = {
  lastUpdatedAt: Date | undefined;
  lastUser: string | undefined;
  process?: ProcessDetail;
};

export const General = ({ lastUpdatedAt, lastUser, process }: GeneralProps) => {
  const formattedUpdatedAt = lastUpdatedAt
    ? formatDistanceToNow(lastUpdatedAt, {
        addSuffix: true,
        locale: deLocale,
      })
    : '-';

  return (
    <Box mb={2}>
      <Stack direction='row' justifyContent='flex-start' spacing={8} mb='1rem'>
        <Stack direction='column'>
          <Typography color='gray'>Prozessgebiet:</Typography>
          <Typography maxWidth={150} noWrap>
            {process?.processArea}
          </Typography>
        </Stack>
        <Stack>
          <Typography color='gray'>Meilenstein</Typography>
          <Typography maxWidth={150} noWrap>
            {process?.milestone}
          </Typography>
        </Stack>
        <Stack>
          <Typography color='gray'>Systemebene:</Typography>
          <Typography maxWidth={150} noWrap>
            {process?.systemLevel}
          </Typography>
        </Stack>
        <Stack>
          <Typography color='gray'>Letzte Änderung:</Typography>
          <Typography maxWidth={150} noWrap>
            {formattedUpdatedAt}
          </Typography>
        </Stack>
        <Stack>
          <Typography color='gray'>Nutzer:</Typography>
          <Typography maxWidth={200} noWrap>
            {lastUser ?? '-'}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Typography color='gray'>Reifegradkriterium:</Typography>
        <Typography>{process?.reifegradkriterium}</Typography>
      </Stack>
    </Box>
  );
};
