import { TableRow } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { columnVisibilityState } from 'store';

import { MemoTableCell } from './MemoTableCell';

type MemoTableRowProps<T> = {
  row: Row<T>;
  userColumns: string[];
};

export const MemoTableRow = ({ row, userColumns }: MemoTableRowProps<any>) => {
  const [columnVisibility] = useRecoilState(columnVisibilityState);

  return useMemo(() => {
    return (
      <TableRow>
        {row.getVisibleCells().map((cell, index) => (
          <MemoTableCell key={index} cell={cell} userColumns={userColumns} />
        ))}
      </TableRow>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, userColumns, columnVisibility.columnVisibility]);
};
