import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { DataGrid, GridColumns, GridRowModel, GridToolbarContainer } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

import { useProjects, useCreateProject, useUpdateProject } from 'api/admin';
import { useModal } from 'hooks/useModal';

import { CreateProjectModal } from './CreateProjectModal';

export const ProjectDashboard = () => {
  const { data: projectsData, isLoading } = useProjects();

  const { enqueueSnackbar } = useSnackbar();

  const { showModal, hideModal } = useModal();

  const updateProjectMutation = useUpdateProject();
  const createProjectMutation = useCreateProject();

  const processRowUpdate = async (newProject: GridRowModel) => {
    updateProjectMutation.mutate({
      id: newProject.id,
      title: newProject.title,
      isLead: newProject.isLead,
    });

    return {
      id: newProject.id,
      title: newProject.title,
      isLead: newProject.isLead,
    };
  };

  const handleProcessRowUpdateError = (error: Error) => {
    enqueueSnackbar(`${error.message}`, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const onAddProject = () => {
    showModal({
      modalType: 'BasicModal',
      modalProps: {
        title: `Projekt anlegen`,
        content: (
          <CreateProjectModal
            onCancel={hideModal}
            onSave={(title) => {
              createProjectMutation.mutate(title);
              hideModal();
            }}
          />
        ),
      },
    });
  };

  return (
    <DataGrid
      loading={isLoading}
      autoHeight
      hideFooter
      disableColumnFilter
      disableDensitySelector
      disableColumnMenu
      rows={projectsData ?? []}
      components={{
        Toolbar: () => (
          <GridToolbarContainer>
            <Button color='primary' startIcon={<AddIcon />} onClick={onAddProject}>
              Projekt hinzufügen
            </Button>
          </GridToolbarContainer>
        ),
      }}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
    />
  );
};

const columns: GridColumns = [
  { field: 'title', headerName: 'Title', width: 300, editable: true },
  { field: 'isLead', headerName: 'Lead', type: 'boolean', editable: true },
];
