/* eslint-disable react-hooks/rules-of-hooks */
import { Stack, Typography, Tooltip, Box } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useKeyPress, useUpdateEffect } from 'ahooks';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useState, useRef, useMemo } from 'react';

import { useUpdateProcess } from 'api';
import { KBD } from 'components';
import { useModal } from 'hooks';
import { InputField, InputFieldType, Process } from 'models';
import {
  getEndOfWeek,
  getStartOfWeek,
  getToday,
  getWeeksInBetween,
  getYearsFromToday,
  useSingleAndDoubleClick,
} from 'utils';

import { HistoryDetailModal } from '../detailView';

import { BooleanInputCell } from './BooleanInputCell';
import { DateInputCell } from './DateInputCell';
import { PreviousValueTooltip } from './PreviousValueTooltip';
import { TextInputCell } from './TextInputCell';

const columnHelper = createColumnHelper<Process['columns']>();

export const columns = (operations: Process[]) =>
  Object.keys(operations[0]?.columns ?? {}).map((k) =>
    columnHelper.accessor(k, {
      header: k,
      cell: ({ getValue, column: { id } }) => {
        const initialValue = getValue<InputField>();
        if (!initialValue) {
          return 'Loading';
        }
        const [cellValue, setCellValue] = useState(initialValue.value);
        const [isEditEnabled, setEditEnabled] = useState(false);
        const inputRef = useRef(null);
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();
        const { id: columnId, processId, isEditable: canEdit, isInputField } = getValue();

        const updateProcessMutation = useUpdateProcess();

        const { showModal } = useModal();
        const weekOptions = useMemo(
          () =>
            getWeeksInBetween(getStartOfWeek(getToday()), getEndOfWeek(getYearsFromToday())).map(
              (week) => format(week, 'I/yyyy')
            ),
          []
        );

        const onCellDoubleClick = () => {
          if (canEdit) {
            setEditEnabled(true);
          }
        };

        const onCellSingleClick = () => {
          if (processId && !canEdit)
            showModal({
              modalType: 'BasicModal',
              modalProps: {
                title: `Detailansicht`,
                content: <HistoryDetailModal processId={processId} />,
              },
            });
        };
        const { handleClick, handleDoubleClick } = useSingleAndDoubleClick(
          onCellSingleClick,
          onCellDoubleClick
        );

        const previousValue = initialValue.previousValue;

        useKeyPress('enter', () => onConfirm(), { target: inputRef });
        useKeyPress('esc', () => onCancel(), { target: inputRef });

        const onBlur = () => {
          onCancel();
        };

        const onCancel = () => {
          enqueueSnackbar(`Abbruch ${id}`, {
            variant: 'info',
            preventDuplicate: true,
          });
          setCellValue(initialValue.value);
          setEditEnabled(false);
        };

        const onConfirm = () => {
          if (columnId && processId) {
            updateProcessMutation.mutate({
              cells: [
                {
                  id: columnId,
                  processId,
                  value: cellValue,
                },
              ],
            });
            setEditEnabled(false);
          }
        };

        useUpdateEffect(() => {
          if (initialValue.type !== InputFieldType.string) {
            onConfirm();
          }
        }, [cellValue]);

        useUpdateEffect(() => {
          if (isEditEnabled) {
            enqueueSnackbar(
              <Stack direction={'column'}>
                <Typography>Auswählen zum bestätigen</Typography>
                <Typography>
                  <KBD>ESC</KBD> zum abbrechen
                </Typography>
              </Stack>,
              {
                key: 'instructions',
                autoHideDuration: null,
                variant: 'default',
                preventDuplicate: true,
                persist: true,
              }
            );
          } else {
            closeSnackbar('instructions');
          }
        }, [isEditEnabled]);

        const renderInputCell = () => {
          if (canEdit) {
            switch (initialValue.type) {
              case InputFieldType['date']:
              case InputFieldType['dateplus']:
                return (
                  <DateInputCell
                    setValue={setCellValue}
                    value={cellValue}
                    isEditActive={isEditEnabled}
                    weekOptions={weekOptions}
                    dateType={initialValue.type}
                    previousValue={previousValue}
                  />
                );
              case InputFieldType['string']:
                return (
                  <TextInputCell
                    setValue={setCellValue}
                    value={cellValue}
                    isEditActive={isEditEnabled}
                    previousValue={previousValue}
                    onClick={onConfirm}
                  />
                );
              case InputFieldType['boolean']:
                return (
                  <BooleanInputCell
                    setValue={setCellValue}
                    value={cellValue}
                    isEditActive={isEditEnabled}
                    previousValue={previousValue}
                  />
                );
              default:
                throw new Error(`Not valid type: ${cellValue}`);
            }
          } else {
            return (
              <Stack direction='row' alignItems='center'>
                <Tooltip title={cellValue ?? 'Kein Eintrag'}>
                  <Typography
                    color={cellValue ? 'inherit' : 'gray'}
                    fontStyle={cellValue ? 'inherit' : 'italic'}
                    minWidth={100}
                    maxWidth={150}
                    fontSize={14}
                    noWrap
                  >
                    {cellValue === 'null' ? 'Kein Eintrag' : cellValue}
                  </Typography>
                </Tooltip>
                {isInputField && previousValue && (
                  <PreviousValueTooltip currentValue={cellValue} previousValue={previousValue} />
                )}
              </Stack>
            );
          }
        };

        return (
          <Box
            sx={{ ':hover': { cursor: 'pointer' } }}
            ref={inputRef}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            onBlur={initialValue.type !== InputFieldType.string ? onBlur : undefined}
          >
            {renderInputCell()}
          </Box>
        );
      },
      enableHiding: !operations[0]?.columns[k].isEditable,
      enableColumnFilter: operations[0]?.columns[k].isEditable,
      filterFn: 'fuzzy',
    })
  );
