/* eslint-disable import/no-duplicates */
import { createTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Router } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setDefaultOptions } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import { SnackbarProvider } from 'notistack';
import { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RecoilRoot } from 'recoil';

import { ErrorFallback, resetErrorBoundary } from 'components';
import { queryClient } from 'lib/react-query';
import { reactLocation, appRoutes } from 'routes';
import { getDesignToken } from 'styles/theme';

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const theme = createTheme(getDesignToken(), deDE);
  setDefaultOptions({ locale: deLocale, weekStartsOn: 1 });

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
          <Suspense>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetErrorBoundary}>
              <QueryClientProvider client={queryClient}>
                {process.env.NODE_ENV === 'development' && (
                  <ReactQueryDevtools position='bottom-left' initialIsOpen={false} />
                )}
                <Router location={reactLocation} routes={appRoutes}>
                  {process.env.NODE_ENV === 'development' && (
                    <ReactLocationDevtools position='bottom-right' />
                  )}
                  <SnackbarProvider
                    preventDuplicate
                    autoHideDuration={1500}
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  >
                    {children}
                  </SnackbarProvider>
                </Router>
              </QueryClientProvider>
            </ErrorBoundary>
          </Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};
