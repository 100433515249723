import { Link, styled } from '@mui/material';
import { Link as RouterLink } from '@tanstack/react-location';

import { Project } from 'models';
import { ROUTE_CONSTANTS } from 'routes';

type ProjectCardProps = {
  id: Project['id'];
  title: Project['title'];
};

const StyledProjectCard = styled('div')(
  () => `
  min-height: 80px;
  border: 1px solid #EFF0F7;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
  border-radius: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1em;
  cursor: pointer;
`
);

export const ProjectCard = ({ id, title }: ProjectCardProps) => {
  return (
    <Link
      component={RouterLink}
      to={`${ROUTE_CONSTANTS.PROJECTS_PATH}/${id}`}
      underline='none'
      sx={{ color: 'black', fontWeight: 700 }}
    >
      <StyledProjectCard>{title}</StyledProjectCard>
    </Link>
  );
};
