import { MouseEvent } from 'react';

import { Filter, ProjectFilter } from 'models';

export const withId =
  <T>(targetId: string | number) =>
  (item: T) =>
    targetId === item['id'];

export const NONE = 'NONE';
export const MORE = 'MORE';

export const getRelevantFilterIndex = (filter: Filter[]) => {
  return filter.length - 1;
};

/**
 * @description Returns true if filter has children
 * @param filterId
 * @param projectFilters
 * @returns boolean
 */
export const getFilterChildren = (
  filterId: Filter['id'],
  projectFilters: ProjectFilter['systemLevels'] | undefined
): boolean => {
  if (projectFilters) {
    if (projectFilters.find(withId(filterId))?.children) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getSystemLevelFilterId = (
  filter: Filter[] | undefined,
  projectFilters?: ProjectFilter | undefined
) => {
  if (filter) {
    if (
      getFilterChildren(filter[getRelevantFilterIndex(filter)].id, projectFilters?.systemLevels) ||
      (filter[0].title === '3' && filter.length === 2)
    ) {
      return MORE;
    } else {
      return filter[getRelevantFilterIndex(filter)].id;
    }
  } else {
    return NONE;
  }
};

export const onClickStopPropagation = (e?: MouseEvent<any>) => {
  e?.stopPropagation();
};

export const stringAvatar = (name: string) => {
  if (!name.includes(' ')) {
    return { children: 'AA' };
  }
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};
