import { Box, Grid, Typography } from '@mui/material';
import { useMount } from 'ahooks';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { useProjects } from 'api';
import { SavedFilters } from 'features/project/filters/Filters';
import { authState } from 'store';
import { storageKey } from 'utils/storage';

import { Favorites } from './Favorites';
import { VehicleProjects } from './VehicleProjects';

export const LandingPage = () => {
  const [savedFilters, setSavedFilters] = useState<SavedFilters[]>([]);
  const projectsQuery = useProjects();
  const [auth, setAuth] = useRecoilState(authState);

  useMount(() => {
    Auth.currentSession().then((res) => {
      setAuth(res.getIdToken().payload);
    });
    try {
      const value = JSON.parse(window.localStorage.getItem(storageKey) as string);
      if (value && value.length > 0) {
        setSavedFilters(value);
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  });

  return (
    <Box sx={{ flexGrow: 1, padding: 6 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h2' component='h1' fontWeight={700}>
            Projekttracking Landingpage
          </Typography>
          {auth && (
            <Typography variant='h6' component='h2' marginTop={2}>
              Hallo {auth['given_name']} {auth['family_name']}(
              {auth['cognito:groups'] && (auth['cognito:groups'] as string[])?.join(', ')})
            </Typography>
          )}
        </Grid>
        <Grid item xs={9} marginTop={6}>
          <Favorites favorites={savedFilters} setFavorites={setSavedFilters} />
        </Grid>
        <Grid item xs={3} marginTop={6}>
          <VehicleProjects projects={projectsQuery} />
        </Grid>
      </Grid>
    </Box>
  );
};
