import { DialogContent, Typography } from '@mui/material';
import { useSearch } from '@tanstack/react-location';

import { useProcess, useProcessHistory } from 'api/process';
import { Process } from 'models';
import { LocationGenerics } from 'routes';

import { Comments } from './Comments';
import { General } from './General';
import { HistoryLog } from './HistoryLog';
import { Inputs } from './Inputs';
import { Links } from './Links';

type HistoryDetailModalProps = {
  processId: Process['id'];
};

export const HistoryDetailModal = ({ processId }: HistoryDetailModalProps) => {
  const { filters } = useSearch<LocationGenerics>();
  const filterSystemLevelLength = filters?.systemLevels?.length ?? 0;
  const systemLevelId = filters?.systemLevels?.[filterSystemLevelLength - 1]?.id ?? '';
  const { data: processHistory } = useProcessHistory(processId);
  const { data: processData } = useProcess(processId, { systemLevelId: systemLevelId });

  return (
    <DialogContent>
      <General
        lastUpdatedAt={processHistory?.[0]?.created}
        lastUser={processHistory?.[0]?.user}
        process={processData}
      />
      <Inputs inputColumns={processData?.columns} />
      {processHistory?.length ? (
        <HistoryLog history={processHistory ?? []} />
      ) : (
        <Typography>Keine Änderungshistorie vorhanden</Typography>
      )}
      <Links links={processData?.links ?? []} />
      <Comments comments={processData?.comments ?? []} processId={processId} />
    </DialogContent>
  );
};
