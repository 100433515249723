import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';
import { toFilter } from 'services/filter.service';

export const getVehicleSystems = () => getAudiFuseApis().adminControllerGetVehicleSystems();

export const useVehicleSystems = () => {
  return useQuery(queryKeys.vehicleSystems.all, () => getVehicleSystems(), {
    select: (data) => data.map(toFilter),
  });
};
