import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';

export const updateProject = (request: { id: string; title: string; isLead: boolean }) =>
  getAudiFuseApis().adminControllerUpdateProject(request);

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation(updateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.adminProjects.all);
    },
  });
};
