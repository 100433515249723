import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';

export const createRole = (request: { title: string }) =>
  getAudiFuseApis().adminControllerCreateRole(request);

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  return useMutation(createRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.roles.all);
    },
  });
};
