import packageJson from '../../package.json';

import { Environment } from './environment.model';

/* eslint-disable no-template-curly-in-string */
export const environment: Environment = {
  version: 'prod',
  build: 'prod',
  release: `${packageJson.version}-prod`,
  environment: 'prod',
  api: 'https://ptl.cloud.audi/ptl-be',
};

// export const environment: Environment = {
//   version: '{{RELEASE_VERSION}}',
//   build: '${BUILD}',
//   release: '${BUILD}-release',
//   environment: '{{ENVIRONMENT}}',
//   api: 'http://localhost:3001/ptl-be',
// };
