import { KeyboardArrowLeftOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';

import { LocationGenerics, ROUTE_CONSTANTS } from 'routes';

export const BackToDashboard = () => {
  const navigate = useNavigate<LocationGenerics>();

  const navigateToLandingPage = () => {
    navigate({ to: `${ROUTE_CONSTANTS.HOME_PATH}` });
  };
  return (
    <Stack m={2}>
      <IconButton
        onClick={() => navigateToLandingPage()}
        sx={{
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
      >
        <KeyboardArrowLeftOutlined sx={{ color: '#008DDD' }} />
        <Typography component={'p'} sx={{ fontSize: 15 }} color='#008DDD' fontWeight={700}>
          Zurück zum Dashboard
        </Typography>
      </IconButton>
    </Stack>
  );
};
