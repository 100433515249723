/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Typography } from '@mui/material';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useSearch } from '@tanstack/react-location';
import {
  FilterFn,
  PaginationState,
  Updater,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useSkipper } from 'hooks';
import { InputField, Process } from 'models';
import { LocationGenerics } from 'routes';
import { columnVisibilityState } from 'store';
import { MORE, NONE } from 'utils';

import { TableHeader } from '..';

import { columns } from './Columns';
import { TableComponent } from './TableComponent';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
}

export type TableContainerProps = {
  hasOverlay: string;
  operations: Process[];
  editableColumns: string[];
  inputColumns: string[];
  pagination: PaginationState;
  setPagination: (pagination: Updater<PaginationState>) => void;
  pageCount: number;
  totalItemsCount: number;
};

export const TableContainer = ({
  hasOverlay,
  operations,
  editableColumns,
  inputColumns,
  pagination,
  setPagination,
  pageCount,
  totalItemsCount,
}: TableContainerProps) => {
  const [columnVisibilityGlobal, setColumnVisibilityState] = useRecoilState(columnVisibilityState);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [data, setData] = useState<Process['columns'][]>([]);
  const [autoResetPageIndex] = useSkipper();
  const showOverlay = hasOverlay === NONE || hasOverlay === MORE;
  const { filters } = useSearch<LocationGenerics>();

  useEffect(() => {
    setData(operations.map((c) => c.columns));
    onShowOnlyOwnedColumnClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operations, inputColumns, editableColumns]);

  const onShowOnlyOwnedColumnClick = () => {
    setColumnVisibility(
      Object.assign(
        {},
        ...inputColumns.filter((c) => !editableColumns.includes(c)).map((c) => ({ [c]: false }))
      )
    );
    setColumnVisibilityState({ columnVisibility: columnVisibilityGlobal.columnVisibility + 1 });
  };

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem((row.getValue(columnId) as InputField).value, value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const tableColumns = columns(operations);
  const memoColumns = useMemo(() => tableColumns, [tableColumns]);
  const memoData = useMemo(() => data, [data]);

  const table = useReactTable({
    data: memoData,
    columns: memoColumns,
    filterFns: { fuzzy: fuzzyFilter },
    state: { columnVisibility, pagination },
    initialState: { pagination: { pageSize: 25 } },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: pageCount,
    debugTable: true,
  });

  useEffect(() => {
    table.setPageIndex(0);
  }, [filters, pagination.pageSize]);

  return (
    <Fragment>
      {showOverlay && (
        <Typography fontWeight='bold' align='center'>
          Bitte Systemebene-Filter verfeinern
        </Typography>
      )}
      <Box
        px={8}
        py={4}
        sx={{
          bgcolor: '#EDF0F2',
          filter: showOverlay ? 'blur(4px)' : '',
          pointerEvents: showOverlay ? 'none' : '',
        }}
      >
        <TableHeader
          table={table}
          editableColumns={editableColumns}
          totalItems={totalItemsCount}
          onShowOnlyOwnedColumnClick={onShowOnlyOwnedColumnClick}
        />
        <TableComponent table={table} pagination={pagination} />
      </Box>
    </Fragment>
  );
};
