import { withAuthenticator } from '@aws-amplify/ui-react';
import { Outlet } from '@tanstack/react-location';
import { Amplify, Auth } from 'aws-amplify';

import { MainLayout } from 'components';
import { GlobalModal } from 'components/modal';
import { AppProvider } from 'providers';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  Auth: {
    userPoolId: 'eu-central-1_pGR9F5a8Y',
    region: 'eu-central-1',
    userPoolWebClientId: '64is1csbk0ap7eao9utagl52s6',
  },
});

function App(user: any) {
  if (user) {
    Auth.currentSession().then((res) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const accessToken = res.getAccessToken();
    });
  }

  return (
    <AppProvider>
      <GlobalModal />
      <MainLayout>
        <Outlet />
      </MainLayout>
    </AppProvider>
  );
}

export default withAuthenticator(App);
