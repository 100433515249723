import {
  Favorite,
  FavoriteBorder,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { useEffect, useState } from 'react';

import { LocationGenerics } from '../../routes';
import { storage } from '../../utils/storage';

import { SaveFilterModal } from './SaveFilterModal';

type Props = {
  handleCollapse: () => void;
  isCollapsed: boolean;
};

export const SaveFilterButton = ({ isCollapsed, handleCollapse }: Props) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const search = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();

  useEffect(() => {
    setIsFavorite(
      storage.findFilter({
        vehicleProjectId: projectId,
        milestones: search.filters?.milestones,
        systemLevels: search.filters?.systemLevels,
        processAreas: search.filters?.processAreas,
        businessObjects: search.filters?.businessObjects,
      })
    );
    if (Object.keys(search.filters ?? {}).length === 0) {
      navigate({
        search: {},
      });
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.filters, isSaveModalOpen, projectId]);

  const openSaveModal = () => {
    setIsSaveModalOpen(true);
  };

  const closeSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  const removeFavoriteFilter = () => {
    const hashId = storage.getHash({
      vehicleProjectId: projectId,
      milestones: search.filters?.milestones,
      systemLevels: search.filters?.systemLevels,
      processAreas: search.filters?.processAreas,
      businessObjects: search.filters?.businessObjects,
    });
    hashId && storage.removeFilter(hashId);
    setIsFavorite(false);
  };
  return (
    <Stack direction='row' m={2}>
      <Box sx={{ flexDirection: 'column' }}>
        <IconButton
          aria-label='favorite'
          onClick={isFavorite ? removeFavoriteFilter : openSaveModal}
          disabled={isDisabled}
        >
          {isFavorite ? <Favorite color='error' /> : <FavoriteBorder />}
        </IconButton>
        <IconButton onClick={handleCollapse}>
          {isCollapsed ? <KeyboardArrowDownOutlined /> : <KeyboardArrowUpOutlined />}
        </IconButton>
      </Box>
      <SaveFilterModal isOpen={isSaveModalOpen} handleClose={closeSaveModal} />
    </Stack>
  );
};
