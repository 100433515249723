import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Typography, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useState, MouseEvent } from 'react';

import { Project, ProjectDetail } from 'models';
import { LocationGenerics, ROUTE_CONSTANTS } from 'routes';

type SelectProjectProps = {
  title: ProjectDetail['title'];
  projects: Project[];
};

export const SelectProject = ({ title, projects }: SelectProjectProps) => {
  const navigate = useNavigate<LocationGenerics>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleProjectSwitch = (projectId: Project['id']) => {
    navigate({
      to: `/${ROUTE_CONSTANTS.PROJECTS_PATH}/${projectId}`,
      search: (prev?) => ({
        ...prev,
      }),
    });
  };

  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <Typography
        component={'p'}
        sx={{ fontSize: 18 }}
        color='black'
        align='center'
        fontWeight={700}
      >
        {title}
      </Typography>
      <IconButton
        id='menu-button'
        aria-controls={open ? 'project-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <KeyboardArrowDownOutlined />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
      >
        {projects?.map((project) => (
          <MenuItem key={project.id} onClick={() => handleProjectSwitch(project.id)}>
            {project.title}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};
