import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import deDe from 'date-fns/locale/de';
import { Fragment, useState } from 'react';

import { InputFieldHistory } from 'models';
import { parseValue, stringAvatar } from 'utils';

type HistoryLogProps = {
  history: InputFieldHistory[];
};

export const HistoryLog = ({ history }: HistoryLogProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Fragment>
      <Button
        onClick={() => setOpen(!isOpen)}
        endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
      >{`Änderungshistorie ${isOpen ? 'ausblenden' : 'einblenden'}`}</Button>
      <Collapse in={isOpen}>
        <List sx={{ maxHeight: 400, overflowY: 'auto' }}>
          {history.map((item, index) => (
            <ListItem key={index} alignItems='flex-start'>
              <ListItemAvatar>
                <Avatar {...stringAvatar(item.user)} />
              </ListItemAvatar>
              <ListItemText
                primary={`${item.user} hat ${item.title} aktualisiert - ${formatDistanceToNow(
                  item?.created,
                  {
                    addSuffix: true,
                    locale: deDe,
                  }
                )}`}
                primaryTypographyProps={{ style: { wordBreak: 'break-word' } }}
                secondary={parseValue(item.value)}
                secondaryTypographyProps={{ fontWeight: 'bold', color: 'green' }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
