import { Role } from 'models/role.model';
import { RoleDto, FieldDto } from 'proxy';

export const toRole = (data: RoleDto): Role => ({
  id: data.id,
  title: data.title,
  ...(data?.fields && toFields(data.fields)),
});

const toFields = (data: FieldDto[]): { [key: string]: boolean } =>
  Object.assign(
    {},
    ...data.map((d) => ({
      [d.title]: true,
    }))
  );
