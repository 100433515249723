import { ThemeOptions } from '@mui/material/styles';

export const getDesignToken = (): ThemeOptions => {
  return {
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
    },
  };
};
