import { useNavigate } from '@tanstack/react-location';
import { useMount } from 'ahooks';
import { Auth } from 'aws-amplify';

import { AdminDashboard } from 'features/admin';
import { ROUTE_CONSTANTS } from 'routes';

export const Admin = () => {
  const navigate = useNavigate();

  useMount(() => {
    async function getUserInfo() {
      const userSession = await Auth.currentSession();
      if (userSession) {
        if (!(userSession['idToken']['payload']['cognito:groups'] as string[])?.includes('admin')) {
          navigate({ to: ROUTE_CONSTANTS.HOME_PATH, replace: true });
        }
      } else {
        navigate({ to: ROUTE_CONSTANTS.HOME_PATH, replace: true });
      }
    }
    getUserInfo();
  });

  return <AdminDashboard />;
};
