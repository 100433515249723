import { Field } from 'models/field.model';
import { RoleDto, FieldDto } from 'proxy';

export const toField = (data: FieldDto): Field => ({
  id: data.id,
  title: data.title,
  ...(data?.roles && toRoles(data.roles)),
});

const toRoles = (data: RoleDto[]): { [key: string]: boolean } =>
  Object.assign(
    {},
    ...data.map((d) => ({
      [d.title]: true,
    }))
  );
