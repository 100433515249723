import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { Process } from 'models';
import { getAudiFuseApis, ProcessControllerGetProcessDetailParams } from 'proxy';
import { toProcessDetail } from 'services';

export const getProcessById = (
  processId: Process['id'],
  params: ProcessControllerGetProcessDetailParams
) => getAudiFuseApis().processControllerGetProcessDetail(processId, params);

export const useProcess = (
  processId: Process['id'],
  params: ProcessControllerGetProcessDetailParams
) => {
  return useQuery(
    queryKeys.process.detailWithParams(processId, params),
    () => getProcessById(processId, params),
    {
      select: (data) => toProcessDetail(data),
      enabled: !!processId,
    }
  );
};
