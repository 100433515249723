/* eslint-disable jsx-a11y/no-autofocus */
import { NativeSelect } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { InputField, InputFieldHistory } from 'models';
import { onClickStopPropagation } from 'utils';

import { InputCellValue } from './InputCellValue';

type BooleanInputCellProps = {
  isEditActive: boolean;
  value: InputField['value'];
  setValue: Dispatch<SetStateAction<InputField['value']>>;
  previousValue?: InputFieldHistory;
};

export const BooleanInputCell = ({
  isEditActive,
  value,
  setValue,
  previousValue,
}: BooleanInputCellProps) => {
  const showValue = (value: string | null) => {
    switch (value) {
      case 'true':
        return 'Ja';
      case 'false':
        return 'Nein';
      default:
        return value;
    }
  };

  if (!isEditActive) {
    return (
      <InputCellValue
        value={showValue(value)}
        previousValue={
          previousValue && { ...previousValue, value: showValue(previousValue.value) ?? '' }
        }
      />
    );
  }

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === 'null') {
      setValue(null);
      return;
    }
    setValue(value);
  };

  return (
    <NativeSelect
      autoFocus
      size='small'
      defaultValue={value ?? ''}
      onChange={handleChange}
      fullWidth
      onClick={onClickStopPropagation}
    >
      <option value={''} disabled>
        Bitte auswählen
      </option>
      <option value={'null'}>Kein Eintrag</option>
      <option value={'true'}>Ja</option>
      <option value={'false'}>Nein</option>
    </NativeSelect>
  );
};
