import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useMatch, useSearch } from '@tanstack/react-location';
import { useQueryClient } from '@tanstack/react-query';
import {
  PaginationState,
  Table as ReactTableType,
  RowData,
  flexRender,
} from '@tanstack/react-table';
import { useRef } from 'react';

import { queryKeys } from 'lib/react-query';
import { ProjectDetail } from 'models';
import { LocationGenerics } from 'routes';

import { ColumnHeaderFilter } from './ColumnHeaderFilter';
import { MemoTableRow } from './MemoTableRow';
import { TablePaginationActions } from './TablePaginationActions';

export type TableProps<T extends RowData> = {
  table: ReactTableType<T>;
  pagination: PaginationState;
};

export const TableComponent = <T extends RowData>({ table, pagination }: TableProps<T>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();
  const { filters } = useSearch<LocationGenerics>();
  const queryClient = useQueryClient();
  const { pageSize, pageIndex } = table.getState().pagination;

  const projectDetailsData = queryClient.getQueryData<ProjectDetail>(
    queryKeys.projects.detailWithParams(projectId, {
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
      systemLevelId: filters?.systemLevels?.[filters?.systemLevels?.length - 1]?.id ?? 'ERROR',
      milestoneIds: filters?.milestones?.map((milestone) => milestone.id).join(','),
      processAreaIds: filters?.processAreas?.map((process) => process.id).join(','),
    })
  );
  const userColumns = projectDetailsData?.editableColumns ?? [];

  return (
    <Box
      style={{
        display: 'block',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        maxWidth: '100vw',
      }}
    >
      <TableContainer ref={tableContainerRef} sx={{ height: 500 }}>
        <Table size='small' stickyHeader>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    align='center'
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      color: userColumns.includes(header.column.id) ? '#008DDD' : '',
                      fontWeight: 700,
                      opacity: 1,
                      borderRight: userColumns.includes(header.column.id)
                        ? 'solid rgba(255, 255, 255, .6)'
                        : '',
                      backgroundColor: userColumns.includes(header.column.id)
                        ? 'lightblue'
                        : 'lightgrey',
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                    )}
                    {header.column.getCanFilter() ? (
                      <div>
                        <ColumnHeaderFilter table={table} column={header.column} />
                      </div>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table?.getRowModel()?.rows?.map((row) => (
              <MemoTableRow key={row.id} row={row} userColumns={userColumns} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        rowsPerPage={pagination.pageSize}
        component='div'
        count={projectDetailsData?.pagination?.totalItems ?? -1}
        page={pagination.pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={(_, page) => {
          table.setPageIndex(page);
        }}
        onRowsPerPageChange={(e) => {
          const size = e.target.value ? Number(e.target.value) : 10;
          table.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
        nextIconButtonProps={{ disabled: !table.getCanNextPage(), onClick: () => table.nextPage() }}
        backIconButtonProps={{
          disabled: !table.getCanPreviousPage(),
          onClick: () => table.previousPage(),
        }}
      />
    </Box>
  );
};
