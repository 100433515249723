import { PublishedWithChangesRounded } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { format } from 'date-fns';

import { InputFieldHistory } from 'models';

type PreviousValueTooltipProps = {
  currentValue?: string | null;
  previousValue: InputFieldHistory;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: '#000000',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const PreviousValueTooltip = ({
  currentValue,
  previousValue,
}: PreviousValueTooltipProps) => {
  return (
    <HtmlTooltip
      placement='top-start'
      title={
        <Typography variant='caption'>
          <strong>{previousValue.value === null ? 'Kein Eintrag' : previousValue.value}</strong> →{' '}
          <strong style={{ color: '#008DDD' }}>
            {currentValue === null ? 'Kein Eintrag' : currentValue}
          </strong>
          <br />
          Änderung am:&nbsp;{format(previousValue.created, 'dd.MM.yyyy HH:mm')}
        </Typography>
      }
    >
      <PublishedWithChangesRounded color='info' fontSize={'small'} />
    </HtmlTooltip>
  );
};
