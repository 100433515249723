import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
  Grid,
  Button,
  Checkbox,
} from '@mui/material';
import { Table as ReactTableType } from '@tanstack/react-table';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';

import { Process } from 'models';
import { columnVisibilityState } from 'store';

type EditTableProps = {
  isOpen: boolean;
  handleClose: () => void;
  table: ReactTableType<Process['columns']>;
  editableColumns: string[];
};

const borderColor = '#EFF0F7';

const StyledColumnCard = styled(Card)(
  () => `
    border: 1px solid ${borderColor} ;
    box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
    border-radius: 5px;
`
);
const StyledInnerColumnCard = styled(Box)(
  () => `
    border: 1px solid ${borderColor};
    border-radius: 2px;
`
);

const StyledGrid = styled(Box)(
  () => `
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0px 20px;
    margin: 20px 20px;
    height: 50vh;
    overflow-y: scroll;
`
);

export const EditTableColumnsModal = ({
  isOpen,
  handleClose,
  table,
  editableColumns,
}: EditTableProps) => {
  const [columnVisibility, setColumnVisibility] = useRecoilState(columnVisibilityState);

  const onChange = () => {
    setColumnVisibility({ columnVisibility: columnVisibility.columnVisibility + 1 });
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>
        <DialogTitle fontWeight={700}>
          Tabellenansicht bearbeiten
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider variant='middle' />

        <StyledColumnCard sx={{ textTransform: 'none', m: 5 }}>
          <Typography variant='subtitle1' m={2} ml={4}>
            Wähle die Spalten aus, die ausgeblendet werden sollen:
          </Typography>

          <StyledGrid>
            <StyledInnerColumnCard>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant='body2' m={1} ml={2} fontWeight={700}>
                    Alle Spalten anzeigen: {table.getVisibleLeafColumns().length}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box display='flex' justifyContent='flex-end' mr={2}>
                    <Checkbox
                      checkedIcon={<Visibility />}
                      icon={<VisibilityOff />}
                      checked={table.getIsAllColumnsVisible()}
                      onClick={() => onChange()}
                      onChange={table.getToggleAllColumnsVisibilityHandler()}
                    />
                  </Box>
                </Grid>
              </Grid>
            </StyledInnerColumnCard>
            {table.getAllLeafColumns().map((column) => (
              <StyledInnerColumnCard key={column.id}>
                <Grid
                  container
                  sx={{
                    backgroundColor:
                      editableColumns.includes(column.id) || !column.getIsVisible()
                        ? !editableColumns.includes(column.id)
                          ? '#EDF0F2'
                          : '#b6b7b8'
                        : '',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant='body2' m={1} ml={2}>
                      {column.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display='flex' justifyContent='flex-end' mr={2}>
                      {!editableColumns.includes(column.id) ? (
                        <Checkbox
                          checkedIcon={<Visibility />}
                          icon={<VisibilityOff />}
                          checked={column.getIsVisible()}
                          onClick={() => onChange()}
                          onChange={column.getToggleVisibilityHandler()}
                        />
                      ) : (
                        <Box display='flex' justifyContent='center' m={1}>
                          <Typography variant='caption' fontSize={14} color='black'>
                            nicht ausblendbar
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </StyledInnerColumnCard>
            ))}
          </StyledGrid>
          <Box display='flex' justifyContent='center'>
            <Typography
              variant='subtitle2'
              mb={2}
              sx={{ color: table.getVisibleLeafColumns().length > 0 ? 'black' : '#AFB1B2' }}
            >
              {table.getAllLeafColumns().length - table.getVisibleLeafColumns().length} Spalte(n)
              werden ausgeblendet
            </Typography>
          </Box>
        </StyledColumnCard>
        <Box display='flex' justifyContent='center'>
          <Button
            type='submit'
            variant='contained'
            size='large'
            sx={{ textTransform: 'none', mb: 3 }}
            onClick={handleClose}
          >
            Änderungen übernehmen
          </Button>
        </Box>
      </Dialog>
    </Fragment>
  );
};
