import { Warning } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import { InputFieldHistory } from 'models';

import { PreviousValueTooltip } from './PreviousValueTooltip';

type InputCellValueProps = {
  value?: string | null;
  previousValue?: InputFieldHistory;
};

export const InputCellValue = ({ value, previousValue }: InputCellValueProps) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={1}>
      <Typography color={value ? 'inherit' : 'gray'} maxWidth={150} fontSize={14} noWrap>
        {value ?? (
          <Stack direction='row' alignItems='center'>
            <Warning color='warning' fontSize={'small'} />
            &nbsp;Kein Eintrag
          </Stack>
        )}
      </Typography>
      {previousValue && <PreviousValueTooltip currentValue={value} previousValue={previousValue} />}
    </Stack>
  );
};
