import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';
import { toProject } from 'services';

export const getProjects = () => getAudiFuseApis().adminControllerGetProjects();

export const useProjects = () => {
  return useQuery(queryKeys.adminProjects.all, () => getProjects(), {
    select: (data) => data.map(toProject),
  });
};
