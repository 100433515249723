import { MoreVert } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Typography,
  styled,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  CardActions,
  Link,
} from '@mui/material';
import { Link as RouterLink } from '@tanstack/react-location';
// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import deLocale from 'date-fns/locale/de';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import type { MouseEvent } from 'react';

import { useProjects } from 'api';
import { SavedFilters } from 'features/project/filters/Filters';
import { ROUTE_CONSTANTS } from 'routes';
import { storage, storageKey } from 'utils/storage';

const StyledFavoriteCard = styled(Card)(
  () => `
    border: 1px solid #EFF0F7;
    box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
    border-radius: 14px;
`
);

export const FavoriteCard = ({
  id,
  vehicleProjectId,
  filterName,
  milestones,
  processAreas,
  systemLevels,
  businessObjects,
  setter,
}: SavedFilters & { setter: Dispatch<SetStateAction<SavedFilters[]>> }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const projectsQuery = useProjects();

  const handleDelete = (event: MouseEvent<HTMLLIElement>) => {
    handleClose(event);
    id && storage.removeFilter(id);
    const updatedFilters = JSON.parse(window.localStorage.getItem(storageKey) as string);
    setter(updatedFilters);
    if (!updatedFilters || updatedFilters.length === 0) {
      setter([]);
    }
  };

  const currentProject = projectsQuery.data?.find((p) => p.id === vehicleProjectId);

  return (
    <Link
      underline='none'
      component={RouterLink}
      to={`${ROUTE_CONSTANTS.PROJECTS_PATH}/${vehicleProjectId}`}
      search={{ filters: { milestones, processAreas, systemLevels, businessObjects } }}
    >
      <StyledFavoriteCard>
        <CardHeader
          title={
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' fontWeight={700}>
                {filterName}
              </Typography>
              {/* <Chip label={status} color='warning' variant='filled' size='small' /> */}
            </Stack>
          }
          action={
            <Fragment>
              <IconButton
                id='menu-button'
                aria-controls={open ? 'favorite-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ zIndex: 99 }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'menu-button',
                }}
              >
                <MenuItem onClick={handleDelete}>Entfernen</MenuItem>
              </Menu>
            </Fragment>
          }
        />
        <CardContent>
          {milestones?.length && (
            <Typography component={'p'} sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
              Meilensteine:{' '}
              <span style={{ color: '#008DDD' }}>
                {milestones.map((filter) => filter.title)?.join(' | ')}
              </span>
            </Typography>
          )}
          {processAreas?.length && (
            <Typography component={'p'} sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
              Prozessgebiete:{' '}
              <span style={{ color: '#008DDD' }}>
                {processAreas.map((filter) => filter.title)?.join(' | ')}
              </span>
            </Typography>
          )}
          {systemLevels?.length && (
            <Typography component={'p'} sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
              Systemebene:{' '}
              <span style={{ color: '#008DDD' }}>
                {systemLevels.map((filter) => filter.title)?.join(' | ')}
              </span>
            </Typography>
          )}
          {businessObjects?.length && (
            <Typography component={'p'} sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
              Geschäftsobjekts:{' '}
              <span style={{ color: '#008DDD' }}>
                {businessObjects.map((filter) => filter.title)?.join(' | ')}
              </span>
            </Typography>
          )}
        </CardContent>
        {currentProject && (
          <CardActions>
            <Typography
              component={'p'}
              color='text.secondary'
              gutterBottom
              sx={{ fontSize: 14, pl: 1 }}
            >
              Letzte Änderung{' '}
              {formatDistanceToNow(currentProject.lastChanged, {
                addSuffix: true,
                locale: deLocale,
              })}
            </Typography>
          </CardActions>
        )}
      </StyledFavoriteCard>
    </Link>
  );
};
