import { Grid, Typography } from '@mui/material';
import { Dispatch, Fragment, SetStateAction } from 'react';

import { SavedFilters } from 'features/project';

import { FavoriteCard } from './FavoriteCard';

type FavoritesProps = {
  favorites: SavedFilters[];
  setFavorites: Dispatch<SetStateAction<SavedFilters[]>>;
};

export const Favorites = ({ favorites, setFavorites }: FavoritesProps) => {
  return (
    <Fragment>
      <Typography variant='h6' component='h4' fontWeight={700} marginBottom={4}>
        Favoriten ({favorites.length})
      </Typography>
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 1, md: 2 }}>
        {favorites.map((favorite) => (
          <Grid item xs={1} key={favorite.id}>
            <FavoriteCard setter={setFavorites} {...favorite} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
