import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { CommentRequest, getAudiFuseApis } from 'proxy';

type UpdateCommentRequest = {
  processId: string;
  commentRequest: CommentRequest;
};

export const updateComments = ({ processId, commentRequest }: UpdateCommentRequest) =>
  getAudiFuseApis().processControllerAddComment(processId, commentRequest);

export const useUpdateComments = () => {
  const queryClient = useQueryClient();
  return useMutation(updateComments, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.process.details());
      queryClient.invalidateQueries(queryKeys.projects.details());
    },
  });
};
