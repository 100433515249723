import { Filter, ProjectFilter } from 'models';
import { FilterDto, ProjectFilterDto } from 'proxy';

export const toProjectFilter = (data: ProjectFilterDto): ProjectFilter => ({
  systemLevels: data.systemLevels,
  milestones: data.milestones,
  processAreas: data.processAreas,
});

export const toFilter = (data: FilterDto): Filter => ({
  id: data.id,
  title: data.title,
  children: data.children ? data.children?.map(toFilter) : undefined,
});
