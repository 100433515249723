import { LinkRounded } from '@mui/icons-material';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as MuiLink,
  Divider,
} from '@mui/material';
import { Fragment } from 'react';

import { Link } from 'models';

type LinksProps = {
  links: Link[];
};

export const Links = ({ links }: LinksProps) => {
  if (!links.length) {
    return null;
  }
  return (
    <Fragment>
      <Divider sx={{ marginY: '1rem' }} />
      <Typography variant='h6'>Links</Typography>
      <List dense>
        {links?.map((link, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <LinkRounded />
            </ListItemIcon>
            <ListItemText
              primary={link.text}
              secondary={
                <MuiLink href={link.uri} target='_blank' referrerPolicy='no-referrer'>
                  {link.uri}
                </MuiLink>
              }
            />
          </ListItem>
        ))}
      </List>
    </Fragment>
  );
};
