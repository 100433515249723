import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';
import { toField } from 'services/field.service';

export const getFields = (systemLevelId: string) =>
  getAudiFuseApis().adminControllerGetFields({ systemLevelId });

export const useFields = (systemLevelId: string) => {
  return useQuery(
    queryKeys.fields.byVehicleSystemId(systemLevelId),
    () => getFields(systemLevelId),
    {
      select: (data) => data.map(toField),
    }
  );
};
