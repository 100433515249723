import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { Filter } from 'models';
import { getAudiFuseApis } from 'proxy';
import { toProjectFilter } from 'services/filter.service';

const milestonesMapping = [
  'StartPPT',
  'PS',
  'PM',
  'PP',
  'PD',
  'ZV',
  'PF',
  'KF',
  'LH',
  'BF',
  'LF',
  'VFF',
  'PVS',
  'OS',
  'SOP',
];

export const getProjectFilters = async () => {
  const filterData = await getAudiFuseApis().projectsControllerGetProjectFilters();

  if (filterData && Object.keys(filterData).length > 0) {
    // sort processareas by alphabetical order
    filterData.processAreas = filterData.processAreas.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    const sortedMilestones = sortMilestonesByMapping(filterData.milestones);
    filterData.milestones = sortedMilestones;
  }
  return filterData;
};

export const useProjectFilters = () => {
  const queryClient = useQueryClient();
  return useQuery(queryKeys.filters.all, () => getProjectFilters(), {
    initialData: queryClient.getQueryData(queryKeys.filters.all),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.filters.all)?.dataUpdatedAt,
    select: (data) => toProjectFilter(data),
    // useErrorBoundary: (error) =>
    //   axios.isAxiosError(error) ? (error?.response?.status ?? 500 >= 500 ? true : false) : true,
  });
};

const sortMilestonesByMapping = (milestones) => {
  const remainingMilestones: Filter[] = [];
  const sortedMilestones: Filter[] = [];

  milestones.forEach((milestone) => {
    const index = milestonesMapping.findIndex((milestoneKey) => milestoneKey === milestone.title);
    if (index !== -1) {
      sortedMilestones[index] = milestone;
    } else {
      remainingMilestones.push(milestone);
    }
  });

  // merge arrays and delete empty array elements
  return sortedMilestones.concat(remainingMilestones).filter((milestone) => milestone);
};
