import { MakeGenerics, ReactLocation, Route } from '@tanstack/react-location';

import { Filter, Project as ProjectModel } from 'models';
import { Admin, Home, Project } from 'pages';

export const ROUTE_CONSTANTS = {
  HOME_PATH: '/',
  ROOT_PATH: '.',
  PROJECTS_PATH: 'projects',
  PROJECT_PATH: ':projectId',
  ADMIN_PATH: 'admin',
};

export type LocationGenerics = MakeGenerics<{
  Search: {
    filters?: {
      milestones?: Filter[];
      processAreas?: Filter[];
      systemLevels?: Filter[];
      businessObjects?: Filter[];
    };
  };
  Params: {
    projectId: ProjectModel['id'];
  };
}>;

export const reactLocation = new ReactLocation<LocationGenerics>();
export const appRoutes: Route<LocationGenerics>[] = [
  {
    path: ROUTE_CONSTANTS.HOME_PATH,
    element: <Home />,
  },
  {
    path: ROUTE_CONSTANTS.PROJECTS_PATH,
    children: [{ path: ROUTE_CONSTANTS.PROJECT_PATH, element: <Project /> }],
  },
  {
    path: ROUTE_CONSTANTS.ADMIN_PATH,
    element: <Admin />,
  },
];
