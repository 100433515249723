import { Box } from '@mui/material';
import { useMatch, useSearch } from '@tanstack/react-location';
import { PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { useProjectFilters, useProjectProcesses, useProjects } from 'api';
import { useProject } from 'api/projects/useProject';
import { LocationGenerics } from 'routes';
import { getSystemLevelFilterId } from 'utils';

import { ProjectDetailsHeader } from './ProjectDetailsHeader';
import { TableContainer } from './table/TableContainer';

export const ProjectDetails = () => {
  const {
    params: { projectId },
  } = useMatch<LocationGenerics>();
  const { filters } = useSearch<LocationGenerics>();

  const projectsQuery = useProjects();
  const filtersQuery = useProjectFilters();
  const projectQuery = useProject(projectId);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const projectDetailProcessesQuery = useProjectProcesses(projectId, {
    systemLevelId: filters?.systemLevels?.join(',') || '',
    page: pageIndex,
    pageSize: pageSize,
    milestoneIds: filters?.milestones?.map((milestone) => milestone.id).join(','),
    processAreaIds: filters?.processAreas?.map((process) => process.id).join(','),
    businessObjectIds: filters?.businessObjects?.map((business) => business.id).join(','),
  });

  const overlayValue = getSystemLevelFilterId(filters?.systemLevels, filtersQuery?.data);
  const getPageCount = projectDetailProcessesQuery.data?.pagination
    ? Math.ceil(
        projectDetailProcessesQuery.data?.pagination?.totalItems /
          projectDetailProcessesQuery.data?.pagination?.pageSize
      )
    : -1;

  return (
    <Box sx={{ flexGrow: 1, overflowX: 'hidden', maxWidth: '100vw' }}>
      <ProjectDetailsHeader title={projectQuery?.data?.title ?? ''} projectData={projectsQuery} />
      <TableContainer
        hasOverlay={overlayValue}
        operations={projectDetailProcessesQuery.data?.processes ?? []}
        editableColumns={projectDetailProcessesQuery.data?.editableColumns ?? []}
        inputColumns={projectDetailProcessesQuery.data?.inputColumns ?? []}
        pagination={pagination}
        setPagination={setPagination}
        pageCount={getPageCount}
        totalItemsCount={projectDetailProcessesQuery.data?.pagination?.totalItems ?? 0}
      />
    </Box>
  );
};
