import { Project, ProjectDetail } from 'models';
import { ProjectDetailDto, ProjectDto } from 'proxy/dtos';

import { toProcess } from './process.service';

export const toProject = (data: ProjectDto): Project => ({
  id: data.id,
  title: data.title,
  lastChanged: new Date(data.lastChanged),
  isLead: data.isLead,
});

export const toProjectDetail = (data?: ProjectDetailDto): ProjectDetail => ({
  id: data?.id || '',
  title: data?.title || '',
  editableColumns: data?.editableColumns,
  inputColumns: data?.processes?.[0]?.columns
    ?.filter((column) => column.isInputField)
    ?.map((c) => c.title),
  processes: data?.processes?.map(toProcess),
  pagination: data?.pagination,
});
