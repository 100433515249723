import { Stack, Typography } from '@mui/material';
import { useSearch } from '@tanstack/react-location';

import { Filter } from 'models';
import { LocationGenerics } from 'routes';

export const FilterSummary = () => {
  const search = useSearch<LocationGenerics>();

  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
      justifyContent='center'
      maxWidth='90%'
      margin={2}
    >
      {search.filters &&
        Object.entries(search.filters).map(([key, values], index) => (
          <Typography key={key + index} sx={{ fontSize: 12 }}>
            {'ausgewählte ' + key.toLocaleUpperCase() + ' (' + (values as Filter[]).length + ') '}:{' '}
            <span style={{ color: '#008DDD' }}>
              {(values as Filter[]).map((filter) => filter.title)?.join(' | ')}
            </span>
          </Typography>
        ))}
    </Stack>
  );
};
