import { SavedFilters } from 'features/project/filters/Filters';

const hash = require('object-hash');

export const hashGenerator = {
  generateHash: (contentToHash: Omit<SavedFilters, 'id' | 'filterName'>) => {
    let returnVal;
    if (contentToHash) {
      returnVal = hash(contentToHash);
    } else {
      returnVal = false;
    }
    return returnVal;
  },
};
