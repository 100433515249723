import { Save } from '@mui/icons-material';
import { IconButton, NativeSelect, Stack, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { useUpdateProcess } from 'api';
import { InputField, InputFieldType } from 'models';
import { onClickStopPropagation } from 'utils';

type DetailInputProps = {
  data: InputField;
  weekOptions: string[];
};

export const DetailInput = ({
  data: { id, type, value, processId },
  weekOptions,
}: DetailInputProps) => {
  const [text, setText] = useState(value ?? '');
  const updateProcessMutation = useUpdateProcess();

  const isDatePlus = type === 'dateplus';

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value === 'null' ? null : event.target.value;
    if (id && processId) {
      updateProcessMutation.mutate({ cells: [{ id, processId, value }] });
    }
  };

  const onSubmit = () => {
    if (id && processId) {
      updateProcessMutation.mutate({ cells: [{ id, processId, value: text }] });
    } else {
      console.warn('Not valid');
    }
  };

  switch (type) {
    case InputFieldType.date:
    case InputFieldType.dateplus:
      return (
        <NativeSelect
          size='small'
          defaultValue={value ?? ''}
          fullWidth
          onChange={onChange}
          onClick={(event) => onClickStopPropagation(event)}
        >
          <option value={''} disabled>
            Bitte auswählen
          </option>
          <option value={'null'}>Kein Eintrag</option>
          {isDatePlus && (
            <option key='vorhanden' value='vorhanden'>
              Vorhanden
            </option>
          )}
          {weekOptions.map((week, index) => (
            <option key={index} value={week}>
              {`KW ${week}`}
            </option>
          ))}
        </NativeSelect>
      );
    case InputFieldType.boolean:
      return (
        <NativeSelect
          size='small'
          defaultValue={value ?? ''}
          onChange={onChange}
          fullWidth
          onClick={(event) => onClickStopPropagation(event)}
        >
          <option value={''} disabled>
            Bitte auswählen
          </option>
          <option value={'null'}>Kein Eintrag</option>
          <option value={'true'}>Ja</option>
          <option value={'false'}>Nein</option>
        </NativeSelect>
      );
    case InputFieldType.string:
      return (
        <Stack direction='row'>
          <TextField
            size='small'
            defaultValue={value}
            fullWidth
            onChange={(event) => setText(event.target.value)}
          />
          {text !== value && (
            <IconButton aria-label='Speichern' onClick={onSubmit} size='small' color='info'>
              <Save />
            </IconButton>
          )}
        </Stack>
      );
    default:
      break;
  }
  return <div>Loading</div>;
};
