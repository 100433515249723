import { Button, Divider, List, Stack, TextField, Typography } from '@mui/material';
import { MouseEvent, ChangeEvent, Fragment, useState } from 'react';

import { useUpdateComments } from 'api/process/useUpdateComments';
import { Comment as CommentType, Process } from 'models';

import { Comment } from './Comment';

type CommentsProps = {
  comments: CommentType[];
  processId: Process['id'];
};

export const Comments = ({ comments, processId }: CommentsProps) => {
  const [commentInput, setCommentInput] = useState('');
  const maxCharacters = 240;
  const commentsLength = comments.length;

  const updateCommentsMutation = useUpdateComments();

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentInput(event?.target.value);
  };

  const submitComment = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateCommentsMutation.mutate(
      { processId, commentRequest: { content: commentInput } },
      {
        onSuccess: () => {
          setCommentInput('');
        },
      }
    );
  };

  return (
    <Fragment>
      <Divider sx={{ marginY: '1rem' }} />
      <Typography variant='h6'>Kommentare ({commentsLength})</Typography>
      <TextField
        value={commentInput}
        onChange={onChange}
        label='Kommentar hinzufügen'
        fullWidth
        multiline
        rows={2}
        maxRows={4}
        inputProps={{ maxLength: maxCharacters }}
        sx={{ marginBottom: '1rem' }}
      />
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography>{`${commentInput?.length} / ${maxCharacters} Zeichen`}</Typography>
        <Button
          type='submit'
          variant='contained'
          disabled={!commentInput.length}
          onClick={submitComment}
        >
          Hinzufügen
        </Button>
      </Stack>
      <List sx={{ maxHeight: 400, width: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
        {comments.map((comment) => (
          <Comment key={comment.id} data={comment} />
        ))}
      </List>
    </Fragment>
  );
};
