import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';
import { toProject } from 'services';

export const getProjects = () => getAudiFuseApis().projectsControllerGetProjects();

export const useProjects = () => {
  return useQuery(queryKeys.projects.all, () => getProjects(), {
    // useErrorBoundary: (error) =>
    //   axios.isAxiosError(error) ? (error?.response?.status ?? 500 >= 500 ? true : false) : true,
    select: (data) => data.map(toProject),
  });
};
