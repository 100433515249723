import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material';
import { Column, Table } from '@tanstack/react-table';
import { setWeek, setYear } from 'date-fns';
import { SyntheticEvent, useMemo } from 'react';

import { InputField } from 'models';

type ColumnHeaderFilterProps = {
  column: Column<any, unknown>;
  table: Table<any>;
};

export const ColumnHeaderFilter = ({ table, column }: ColumnHeaderFilterProps) => {
  const columnFilterValue = column.getFilterValue() as string | null;

  const sortedUniqueValues: InputField[] = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues()]
  );

  const valueType = sortedUniqueValues?.[0]?.type;

  const parseValue = (value: string) => {
    if (
      (valueType === 'date' || valueType === 'dateplus') &&
      value !== 'Kein Eintrag' &&
      value !== 'Vorhanden'
    ) {
      const [week, year] = value.split('/');
      const resultWeek = setWeek(new Date(), parseInt(week));
      const finalResult = setYear(resultWeek, parseInt(year));
      return finalResult.getTime();
    } else {
      return new Date().getTime();
    }
  };

  const sortFunction = (a: string, b: string) => {
    switch (valueType) {
      case 'date':
      case 'dateplus':
        return parseValue(a) - parseValue(b);
      default:
        return 0;
    }
  };

  const generateFilters = () => {
    const uniqueValues = sortedUniqueValues
      .map((item) => item.value ?? 'Kein Eintrag')
      .sort(sortFunction)
      .filter((i) => i !== 'Kein Eintrag' && i !== 'Vorhanden');
    uniqueValues.unshift('Kein Eintrag');
    valueType === 'dateplus' && uniqueValues.unshift('Vorhanden');
    return [...new Set(uniqueValues)];
  };

  const onChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'clear') {
      table.setColumnFilters([]);
    } else {
      column.setFilterValue(parseOption(value));
    }
  };

  const renderOptions = (value: string) => {
    switch (value) {
      case 'true':
        return 'Ja';
      case 'false':
        return 'Nein';
      default:
        return value;
    }
  };

  const parseOption = (value: string | null) => {
    switch (value) {
      case 'Ja':
        return 'true';
      case 'Nein':
        return 'false';
      case 'Kein Eintrag':
        return null;
      default:
        return value;
    }
  };

  return (
    <Autocomplete
      sx={{ maxWidth: 200 }}
      size='small'
      id={`${column.id}-filter`}
      options={generateFilters() ?? []}
      getOptionLabel={renderOptions}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option === value}
      value={columnFilterValue}
      disablePortal
      style={{ width: 500 }}
      renderInput={(params) => <TextField placeholder='Filtern' {...params} />}
    />
  );
};
