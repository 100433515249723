/* eslint-disable jsx-a11y/no-autofocus */
import { NativeSelect } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { InputField, InputFieldHistory, InputFieldType } from 'models';
import { onClickStopPropagation } from 'utils';

import { InputCellValue } from './InputCellValue';

type DateInputCellProps = {
  isEditActive: boolean;
  value: InputField['value'];
  setValue: Dispatch<SetStateAction<InputField['value']>>;
  weekOptions: string[];
  dateType: InputFieldType;
  previousValue?: InputFieldHistory;
};

export const DateInputCell = ({
  value,
  setValue,
  isEditActive,
  weekOptions,
  dateType,
  previousValue,
}: DateInputCellProps) => {
  const isDatePlus = dateType === 'dateplus';

  const showValue = (value: string | null) =>
    value ? (value === 'vorhanden' ? 'Vorhanden' : `KW ${value}`) : undefined;

  if (!isEditActive) {
    return (
      <InputCellValue
        value={showValue(value)}
        previousValue={
          previousValue && { ...previousValue, value: showValue(previousValue.value) ?? '' }
        }
      />
    );
  }

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === 'null') {
      setValue(null);
      return;
    }
    setValue(event.target.value);
  };

  return (
    <NativeSelect
      autoFocus
      size='small'
      defaultValue={value ?? ''}
      onChange={handleChange}
      fullWidth
      onClick={onClickStopPropagation}
    >
      <option value={''} disabled>
        Bitte auswählen
      </option>
      <option value={'null'}>Kein Eintrag</option>
      {isDatePlus && (
        <option key='vorhanden' value='vorhanden'>
          Vorhanden
        </option>
      )}
      {weekOptions.map((week, index) => (
        <option key={index} value={week}>
          {`KW ${week}`}
        </option>
      ))}
    </NativeSelect>
  );
};
