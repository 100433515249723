import { Container, Grid } from '@mui/material';
import { useMount } from 'ahooks';
import { ReactNode, useState } from 'react';

import { Header } from 'components';

type MainLayoutProps = {
  children: ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [headerHeight, setHeaderHeight] = useState(71);

  useMount(() => {
    const height = document.querySelector('header')?.offsetHeight;
    height && setHeaderHeight(height);
  });

  return (
    <Container>
      <Header />
      <Grid
        flexDirection={'column'}
        container
        component={'main'}
        sx={{ position: 'absolute', left: 0, top: headerHeight }}
      >
        {children}
      </Grid>
    </Container>
  );
};
