import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import deLocale from 'date-fns/locale/de';
import { Fragment } from 'react';

import { Comment as CommentType } from 'models';
import { stringAvatar } from 'utils';

type CommentProps = {
  data: CommentType;
};

export const Comment = ({ data: { content, created, user } }: CommentProps) => {
  const formattedCreated = formatDistanceToNow(created, {
    addSuffix: true,
    locale: deLocale,
  });
  return (
    <ListItem alignItems='flex-start'>
      <ListItemAvatar>
        <Avatar {...stringAvatar(user)} />
      </ListItemAvatar>
      <ListItemText
        primary={content}
        primaryTypographyProps={{ style: { wordBreak: 'break-word' } }}
        secondary={
          <Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component='span'
              variant='body2'
              color='text.primary'
            >
              {user}
            </Typography>
            &nbsp;-&nbsp;{formattedCreated}
          </Fragment>
        }
      />
    </ListItem>
  );
};
