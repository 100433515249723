import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { ChangeEvent, Fragment, useState } from 'react';

type CreateProjectModalProps = {
  onSave: (title: string) => void;
  onCancel: () => void;
};

export const CreateProjectModal = ({ onSave, onCancel }: CreateProjectModalProps) => {
  const [title, setTitle] = useState('');

  return (
    <Fragment>
      <DialogContent>
        <TextField
          margin='dense'
          id='name'
          label='Titel'
          type='text'
          fullWidth
          variant='standard'
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setTitle(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Abbrechen</Button>
        <Button onClick={() => onSave(title)}>Speichern</Button>
      </DialogActions>
    </Fragment>
  );
};
