import { Stack } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { Fragment, useState } from 'react';

import { Project, ProjectDetail } from 'models';

import { BackToDashboard } from './BackToDashboard';
import { Filters } from './filters/Filters';
import { FilterSummary } from './filters/FilterSummary';
import { SaveFilterButton } from './SaveFilterButton';
import { SelectProject } from './SelectProject';

type ProjectDetailsHeaderProps = {
  title: ProjectDetail['title'];
  projectData: UseQueryResult<Project[], unknown>;
};

export const ProjectDetailsHeader = ({ title, projectData }: ProjectDetailsHeaderProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Fragment>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <BackToDashboard />
        <SelectProject title={title} projects={projectData.data ?? []} />
        <SaveFilterButton handleCollapse={handleCollapse} isCollapsed={isCollapsed} />
      </Stack>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        {!isCollapsed ? <Filters /> : <FilterSummary />}
      </Stack>
    </Fragment>
  );
};
