/* eslint-disable jsx-a11y/no-autofocus */
import { Save } from '@mui/icons-material';
import { IconButton, Stack, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { InputField, InputFieldHistory } from 'models';
import { onClickStopPropagation } from 'utils';

import { InputCellValue } from './InputCellValue';

type TextInputCellProps = {
  isEditActive: boolean;
  value: InputField['value'];
  setValue: Dispatch<SetStateAction<InputField['value']>>;
  previousValue?: InputFieldHistory;
  onClick: () => void;
};

export const TextInputCell = ({
  value,
  setValue,
  isEditActive,
  previousValue,
  onClick,
}: TextInputCellProps) => {
  if (!isEditActive) {
    return <InputCellValue value={value} previousValue={previousValue} />;
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <Stack direction='row'>
      <TextField
        autoFocus
        size='small'
        defaultValue={value}
        onChange={handleChange}
        fullWidth
        onClick={onClickStopPropagation}
      />
      {previousValue?.value !== value && (
        <IconButton
          aria-label='Speichern'
          onClick={(event) => {
            onClickStopPropagation(event);
            onClick();
          }}
          size='small'
          color='info'
        >
          <Save />
        </IconButton>
      )}
    </Stack>
  );
};
