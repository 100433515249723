import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';

export const createProject = (title: string) =>
  getAudiFuseApis().adminControllerCreateProject({ title });

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation(createProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.adminProjects.all);
    },
  });
};
