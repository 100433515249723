import { SavedFilters } from 'features/project/filters/Filters';

import { hashGenerator } from './hashGenerator';

const storagePrefix = 'ptl_';
export const storageKey = 'savedFilters';

export const storage = {
  getLocalRefreshToken(): string {
    const refreshToken = JSON.parse(
      window.localStorage.getItem(`${storagePrefix}refresh_token`) as string
    );
    return refreshToken;
  },
  getLocalAccessToken() {
    const accessToken = JSON.parse(
      window.localStorage.getItem(`${storagePrefix}access_token`) as string
    );
    return accessToken;
  },
  updateLocalAccessToken(token: string) {
    let accessToken = JSON.parse(
      window.localStorage.getItem(`${storagePrefix}access_token`) as string
    );
    accessToken = token;
    this.setLocalAccessToken(accessToken);
  },
  setLocalRefreshToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}refresh_token`, JSON.stringify(token));
  },
  setLocalAccessToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}access_token`, JSON.stringify(token));
  },
  removeAllTokens: () => {
    window.localStorage.removeItem(`${storagePrefix}access_token`);
    window.localStorage.removeItem(`${storagePrefix}refresh_token`);
  },
  addNewFilter: (newFilter: SavedFilters) => {
    const hashValue = hashGenerator.generateHash({
      vehicleProjectId: newFilter.vehicleProjectId,
      milestones: newFilter.milestones,
      systemLevels: newFilter.systemLevels,
      processAreas: newFilter.processAreas,
      businessObjects: newFilter.businessObjects,
    });
    if (window.localStorage.getItem(storageKey)) {
      const savedFilters = JSON.parse(window.localStorage.getItem(storageKey) as string);
      const isExisting = savedFilters.some((filters: SavedFilters) => filters.id == hashValue);
      if (!isExisting && !newFilter.id) {
        newFilter.id = hashValue;
        savedFilters.push(newFilter);
        window.localStorage.removeItem(storageKey);
        window.localStorage.setItem(storageKey, JSON.stringify(savedFilters));
        return 'SavedFilterSuccess';
      } else {
        return 'SavedFilterDuplicate';
      }
    } else {
      const newFilters: SavedFilters[] = [];
      newFilter.id = hashValue;
      newFilters.push(newFilter);
      window.localStorage.setItem(storageKey, JSON.stringify(newFilters));
      return 'SavedFilterSuccess';
    }
  },
  findFilter: (filter: Omit<SavedFilters, 'id' | 'filterName'>) => {
    const hashValue = hashGenerator.generateHash(filter);
    if (window.localStorage.getItem(storageKey)) {
      const savedFilters = JSON.parse(window.localStorage.getItem(storageKey) as string);
      const isExisting: boolean = savedFilters.some(
        (filters: SavedFilters) => filters.id == hashValue
      );
      return isExisting;
    } else {
      return false;
    }
  },
  getHash: (filter: Omit<SavedFilters, 'id' | 'filterName'>): string | undefined => {
    const hashValue = hashGenerator.generateHash(filter);
    if (window.localStorage.getItem(storageKey)) {
      const savedFilters = JSON.parse(window.localStorage.getItem(storageKey) as string);
      const isExisting = savedFilters.find((filters: SavedFilters) => filters.id === hashValue);
      return isExisting.id;
    } else {
      return undefined;
    }
  },
  removeFilter: (hashValue: string) => {
    const savedFilters = JSON.parse(window.localStorage.getItem(storageKey) as string);
    const updatedFilters = savedFilters.filter((data: SavedFilters) => data.id !== hashValue);
    window.localStorage.removeItem(storageKey);
    if (updatedFilters.length > 0) {
      window.localStorage.setItem(storageKey, JSON.stringify(updatedFilters));
    }
  },
};
