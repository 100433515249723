import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { Project } from 'models';
import { ProjectsControllerGetProjectProcessParams, getAudiFuseApis } from 'proxy';
import { toProjectDetail } from 'services';
import { MORE, NONE } from 'utils';

export const getProjectProcessesById = (
  projectId: Project['id'],
  params: ProjectsControllerGetProjectProcessParams
) => getAudiFuseApis().projectsControllerGetProjectProcess(projectId, params);

export const useProjectProcesses = (
  projectId: Project['id'],
  params: ProjectsControllerGetProjectProcessParams
) => {
  return useQuery(
    queryKeys.projects.detailWithParams(projectId, params),
    () => getProjectProcessesById(projectId, params),
    {
      select: (data) => toProjectDetail(data),
      enabled: params.systemLevelId === NONE || params.systemLevelId === MORE ? false : true,
      keepPreviousData: true,
    }
  );
};
