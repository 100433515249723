import { FilterAltOff } from '@mui/icons-material';
import { Button, Stack, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useProjectFilters } from 'api/projects/useProjectFilters';
import { Filter } from 'models';
import { LocationGenerics } from 'routes';

import FilterCheckboxes from './FilterCheckboxes';

type HeadFilter = 'milestones' | 'processAreas' | 'systemLevels';
type SubFilter = {
  milestones?: Filter[];
  processAreas?: Filter[];
  systemLevels?: Filter[];
  businessObjects?: Filter[];
};
export type SavedFilters = {
  id?: string;
  vehicleProjectId: string;
  filterName: string;
} & SubFilter;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped:not(:last-of-type), .MuiToggleButtonGroup-grouped:not(:first-of-type)':
    {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderLeft: '1px solid',
      marginLeft: 0,
    },
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '&.Mui-selected': {
    borderColor: '#0F97C7',
    border: '2px solid #0F97C7 !important',
    backgroundColor: 'white',
    color: '#0F97C7',
  },
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 8,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  margin: '0 1em',
  border: '1px solid',
  minHeight: 50,
  minWidth: 50,
  fontWeight: 700,
  wordBreak: 'break-word',
  hyphens: 'auto',
}));

export const Filters = () => {
  const [headFilter, setHeadFilter] = useState<HeadFilter>('systemLevels');
  const navigate = useNavigate<LocationGenerics>();
  const search = useSearch<LocationGenerics>();
  const projectFiltersQuery = useProjectFilters();
  const { t } = useTranslation();

  const handleFilters = (_event: MouseEvent<HTMLElement>, newHeadFilter: HeadFilter) => {
    if (newHeadFilter) {
      setHeadFilter(newHeadFilter);
    }
  };

  const handleSubFilters = (
    _event: React.MouseEvent<HTMLElement>,
    systemFilters: Filter[] | Filter
  ) => {
    const systemLevelSearchValue = systemFilters as Filter;
    console.log(search.filters?.systemLevels?.length);
    if (search.filters?.systemLevels?.length) {
      navigate({
        search: (prev?) => ({
          ...prev,
          filters: {
            ...prev?.filters,
            [headFilter]:
              headFilter === 'systemLevels'
                ? [{ id: systemLevelSearchValue.id, title: systemLevelSearchValue.title }]
                : (systemFilters as Filter[]).length === 0
                ? undefined
                : systemFilters,
          },
        }),
      });
    }
  };

  const handleSystemLevelSearch = (newValues: any) => {
    navigate({
      search: (prev?) => ({
        ...prev,
        filters: {
          ...prev?.filters,
          systemLevels: newValues,
        },
      }),
    });
  };

  const resetHeadFilters = () => {
    navigate({
      search: (prev?) => ({ ...prev, filters: { ...prev?.filters, [headFilter]: undefined } }),
    });
  };

  if (!projectFiltersQuery?.data) {
    return <div>Loading</div>;
  }

  return (
    <Stack
      direction='column'
      spacing={4}
      alignItems='center'
      justifyContent='center'
      maxWidth='90%'
      margin={2}
    >
      <StyledToggleButtonGroup
        value={headFilter}
        exclusive
        onChange={handleFilters}
        aria-label='head filter'
      >
        {Object.entries(projectFiltersQuery.data).map(([key]) => (
          <StyledToggleButton
            key={key}
            value={key}
            aria-label={key}
            sx={{ minWidth: 200 }}
            disabled={
              search.filters === undefined ||
              (headFilter !== 'systemLevels' && search.filters.systemLevels?.length === 0)
            }
          >
            <Typography variant='button' fontWeight={700}>
              {t(key)}
            </Typography>
            <Typography variant='caption' textTransform='lowercase'>
              ({search?.filters?.[key as HeadFilter]?.length ?? 0}) ausgewählt
            </Typography>
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
      <Stack direction='row' alignItems='center' justifyContent='center'>
        {headFilter === 'systemLevels' ? (
          <FilterCheckboxes
            filterData={projectFiltersQuery.data[headFilter]}
            selectedCheckboxes={search?.filters}
            onSystemLevelSearch={(values) => handleSystemLevelSearch(values)}
            resetHeadFilters={resetHeadFilters}
          />
        ) : (
          <>
            <StyledToggleButtonGroup
              value={search?.filters?.[headFilter] ?? undefined}
              onChange={handleSubFilters}
              size='small'
            >
              {projectFiltersQuery.data[headFilter].map((subFilter) => (
                <StyledToggleButton
                  key={subFilter.id}
                  value={subFilter}
                  lang='de'
                  sx={{ fontSize: 10 }}
                  selected={search?.filters?.[headFilter]
                    ?.map((filter) => filter.id)
                    .includes(subFilter.id)}
                >
                  {subFilter.title.toLocaleUpperCase()}
                </StyledToggleButton>
              ))}
            </StyledToggleButtonGroup>
            <Button
              startIcon={<FilterAltOff fontSize='small' />}
              onClick={resetHeadFilters}
              disabled={
                Array.isArray(search?.filters?.[headFilter])
                  ? !search?.filters?.[headFilter]?.length
                  : true
              }
            >
              <Typography sx={{ fontSize: 10 }}>Filter zurücksetzen</Typography>
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
