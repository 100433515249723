import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';

import { InputField } from 'models';
import {
  getWeeksInBetween,
  getStartOfWeek,
  getToday,
  getEndOfWeek,
  getYearsFromToday,
} from 'utils';

import { DetailInput } from './DetailInput';

type InputsProps = {
  inputColumns:
    | {
        [key: string]: InputField;
      }
    | undefined;
};

export const Inputs = ({ inputColumns }: InputsProps) => {
  const weekOptions = useMemo(
    () =>
      getWeeksInBetween(getStartOfWeek(getToday()), getEndOfWeek(getYearsFromToday())).map((week) =>
        format(week, 'I/yyyy')
      ),
    []
  );
  if (!inputColumns) {
    return (
      <Alert severity='info'>
        <AlertTitle>Keine Eingaben möglich</AlertTitle>
        Sie können keine Eingaben für diesen Prozess vornehmen.
      </Alert>
    );
  }

  return (
    <Box p={2} border='1px solid black' borderRadius={2} bgcolor='rgb(229, 246, 253)' mb={2}>
      <Typography variant='h6'>Eingabe</Typography>
      <Stack direction='row' spacing={4} overflow='auto' pr={2} py={4}>
        {Object.entries(inputColumns).map(([key, value]) => (
          <Stack key={key}>
            <Typography sx={{ minWidth: 100, maxWidth: 200 }} noWrap>
              {key}
            </Typography>
            <DetailInput data={value} weekOptions={weekOptions} />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
