import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'lib/react-query';
import { getAudiFuseApis } from 'proxy';
import { toFilter } from 'services/filter.service';

export const getBusinessObjects = () => getAudiFuseApis().projectsControllerGetBusinessObjects();

export const useBusinessObjects = () => {
  const queryClient = useQueryClient();
  return useQuery(queryKeys.businessObjects.all, () => getBusinessObjects(), {
    initialData: queryClient.getQueryData(queryKeys.businessObjects.all),
    initialDataUpdatedAt: queryClient.getQueryState(queryKeys.businessObjects.all)?.dataUpdatedAt,
    select: (data) => data.map(toFilter),
  });
};
