import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';

import { ProjectDashboard } from './ProjectDashboard';
import { RolePermissionDashboard } from './RolePermissionDashboard';

export const AdminDashboard = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Projekte' id='projects' />
          <Tab label='Rollen' id='roles' />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProjectDashboard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RolePermissionDashboard />
      </TabPanel>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, display: 'flex' }}>{children}</Box>}
    </div>
  );
}
