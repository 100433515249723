import { Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';

import { environment as env } from '../environments';

async function authRequestInterceptor(config: AxiosRequestConfig) {
  await Auth.currentSession().then((res) => {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    const token = res.getIdToken().getJwtToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/json';
    }
  });
  return config;
}

export const AXIOS_INSTANCE = Axios.create({
  baseURL: env.api,
  timeout: 20000,
});

AXIOS_INSTANCE.interceptors.request.use(authRequestInterceptor);
AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const message = error.response?.data?.message || error.message;
    console.error(message);

    return Promise.reject(message);
  }
);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
